import './Profile.css'
import { AuthContext } from '../../context/AuthContext'
import { useContext, useEffect, useState } from 'react'
import ProfileEditor from './ProfileEditor'

export const Profile = ({style, mobile, ...props}) => {
    const {user} = useContext(AuthContext)
    const [showProfileEditor, setShowProfileEditor] = useState(false)

    const toggleProfileEditor = () => {
        setShowProfileEditor(!showProfileEditor)
    }

    return (<>
        <div 
            onClick={toggleProfileEditor}
            className={`profile-picture ${mobile && 'profile-picture--mobile' || 'profile-picture--full'}`} 
            style={{...style, backgroundImage:`url(${user?.photoURL})`, cursor: 'pointer'}}
        /> 
        <ProfileEditor 
            open={showProfileEditor} 
            onClose={toggleProfileEditor}
        />
    </>)
}

export default Profile;