import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'

import * as dates from 'date-arithmetic'
import { Calendar, Views, Navigate, DateLocalizer } from 'react-big-calendar'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import moment from 'moment'

export default function ThreeDayWeek({
  date,
  localizer,
  max = moment().endOf('day'),
  min = moment().startOf('day'),
  scrollToTime = moment().startOf('day'),
 
  ...props
}) {
  console.log("Got localizer", localizer, typeof localizer)
  const currRange = useMemo(
    () => ThreeDayWeek.range(date, { localizer }),
    [date, localizer]
  )

  return (
    <TimeGrid
      date={moment(date).toDate()}
      eventOffset={15}
      localizer={localizer}
      max={moment(max).toDate()}
      min={moment(min).toDate()}
      range={currRange}
      scrollToTime={scrollToTime}
      {...props}
    />
  )
}

ThreeDayWeek.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
}

ThreeDayWeek.range = (date, { localizer }) => {
  const start = moment(date).clone()
  let current = start.clone()
  const range = []

  // implement moment less than equal to current 
  while(range.length < 3) {
   console.log("current", current.day())
  //  if(![6, 0].includes(current.day())) {
  //   range.push(current.clone())
  //  }
    
    range.push(range.length == 2 ?current.clone().endOf('day'): current.clone().startOf('day'))
    current.add(1, 'days')
  }
  // while (current.isBefore(end)) {
  //   range.push(current)
  //   current.add(1, 'day')
  // }

  console.log("Got range", date, range)
  return [...range]
}

ThreeDayWeek.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return moment(date).add(-3, 'day')

    case Navigate.NEXT:
      return moment(date).add(3, 'day')

    default:
      return moment(date)
  }
}

ThreeDayWeek.title = (date) => {
  const start = moment(date).clone()
  const end =  start.clone().add(2, 'day')
  return `${start.format('L')} - ${end.format('L')}`
}
