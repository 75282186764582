import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import './Note.css';
import {format, parseISO } from 'date-fns'

const Notes = ({notes, selectNote, ...props}) => {
    const [currentNote, setCurrentNote] = React.useState(0);

    const handleNext = () => {
        const next = currentNote + 1 >= notes.length-1 ? notes.length -1 : currentNote +1;
        setCurrentNote(next)
    }

    const handlePrev = () => {
        const prev = currentNote - 1 < 1 ? 0 : currentNote -1;
        setCurrentNote(prev)
    }

    const note = notes[currentNote];

    return (
        <div className="notes">
    
            <div className="note" >
                <small className="date">{format(parseISO(note.date), "dd.MM.yyyy HH:mm")}</small>
                <h1 style={{cursor:'pointer'}} onClick={() => selectNote(note)}>{note.title}</h1>
                
                <p>
                    {note.text}
                </p>

                <div className="navigation">
                    <span type="button" className="button-next-prev" onClick={handlePrev}>{"<"}</span>
                    <span type="button" className="button-next-prev" onClick={handleNext}>{">"}</span>
                </div>
            </div>
        </div>

    )
}

export default Notes;