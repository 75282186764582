import React, {useState, useContext, useEffect} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { DatePicker } from "@mui/x-date-pickers";
import './CalendarEditor.css';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import "moment/locale/hr";
import { calendarService } from '../../services/calendar.service';


import Autocomplete from '@mui/material/Autocomplete';
import { AuthContext } from '../../context/AuthContext';
import useWindowSize from '../../hooks';
import {z} from "zod";
import { patientService } from '../../services';

const calendarEventSchema = z.object({
    summary: z.string({required_error: "Ime je obavezno polje", invalid_type_error: "Ime je obavezno polje"}), 
    description: z.string().optional().nullable(), 
    start: z.object({
        dateTime: z.string().datetime({required_error: "Početak je obavezno polje", invalid_type_error: "Početak je obavezno polje"})
    }),
    end: z.object({
        dateTime: z.string().datetime({required_error: "Kraj je obavezno polje", invalid_type_error: "Kraj je obavezno polje"})
    }),  
    calendarId: z.string(),
    //email: z.string({required_error: "Email je obavezno polje", invalid_type_error: "Email je obavezno polje"}),
})

const calendarPatientEventSchema = z.object({
    summary: z.string({required_error: "Ime je obavezno polje", invalid_type_error: "Ime je obavezno polje"}), 
    description: z.string().optional().nullable(), 
    start: z.object({
        dateTime: z.string().datetime({required_error: "Početak je obavezno polje", invalid_type_error: "Početak je obavezno polje"})
    }),
    end: z.object({
        dateTime: z.string().datetime({required_error: "Kraj je obavezno polje", invalid_type_error: "Kraj je obavezno polje"})
    }),  
    calendarId: z.string(),
    //email: z.string({required_error: "Email je obavezno polje", invalid_type_error: "Email je obavezno polje"}),
})

const CalendarEditor = ({event, patient, onSubmit, onDelete, onClose, ...props}) => {
    const size = useWindowSize();
    const { user} = useContext(AuthContext)

    const [title, setTitle] = React.useState(null)
    const [text, setText] = React.useState(null)
    const [email, setEmail] = React.useState(patient?.email ?? null)
    const [eventId, setEventId] = useState(null)
    const [calendarId, setCalendarId] = React.useState('primary')
    const [startTime, setStartTime] = React.useState(new Date())
    const [startDate, setStartDate] = React.useState(new Date())
    //const [end, setEnd] = React.useState(new Date())
    const [endTime, setEndTime] = React.useState(null)
    const [calendarList, setCalendarList] = useState([])
    const [fullScreen, setFullScreen] = useState(false)
    const [errors, setErrors] = useState()
    const [patients, setPatients] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleting, setDeleting] = useState(false)
    
    const clearState = () => {
        setText(null)
        setTitle(patient?.name ?? null)
        setStartTime(null)
        setEndTime(null)
        setStartDate(null)
        setEmail(patient?.email ?? null)
        setErrors(null)
        setEventId(null)
    }

    useEffect(() => {
        setText(null)
        setTitle(patient?.name ?? null)
        setEmail(patient?.email ?? null)
        console.log("Got calendar editor", patient?.email)
        const fetchData = async () => {
            const calendarList = await calendarService.getCalendars();
            console.log("Calendar list", calendarList)
            setCalendarList(calendarList)
        }
        fetchData()
    }, [patient])

    useEffect(() => {
        (async() => {
            const response = await patientService.getAllPatients()
            setPatients(response.map(v => ({id: v.id, label: v.name})))
        })()

    }, [])

    useEffect(() => {
        clearState()
        console.log("GOt event to edit", event)
        if(event) {
            setErrors()
            setEventId(event?.id ?? null)
            setText(event && event.description || null)
            setTitle(event?.title ?? null)
            setStartTime(event && moment(event.start).format("HH:mm") || null)
            setStartDate(event && moment(event.start) || null)
            //setEnd(event &&  moment(event.end).toDate() || null)
            setEndTime(event && moment(event.end).format("HH:mm") || null)
            setCalendarId(event && event.calendarId || calendarList?.find(c => c.id == user.email)?.id)
            setEmail(event?.email ?? patient?.email ?? null)
        }

    }, [event])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors()
        const start = moment(`${moment(startDate).format("YYYY-MM-DD")}T${moment(startTime, "HH:mm").format("HH:mm:ss")}` ).toISOString()
        const end = moment(`${moment(startDate).format("YYYY-MM-DD")}T${moment(endTime, "HH:mm").format("HH:mm:ss")}` ).toISOString()

        const data = {
            description: text, 
            summary: title, 
            start: {dateTime: start}, 
            end: {dateTime: end}, 
            time: moment(start).diff(moment(end), 'minutes'),
            calendarId,
            calendarChanged: event && event?.calendarId != calendarId || false,
            oldCalendarId: event?.calendarId,
            email: email,
            id: eventId,
            eventId: eventId
        }

        try {
            setLoading(true)
            patient && calendarPatientEventSchema.parse(data) || calendarEventSchema.parse(data)
            await onSubmit(data)
            clearState()
        }
        catch(error) {
            console.error("Error", error)
            setErrors(error)
        }
        finally {
            setLoading(false)
        }
    }

    const handleDelete = async () => {
        if(window.confirm('Jeste li sigurni da želite obrisati ovaj termin?')) {
            try {
                setDeleting(true)
                console.log("Delete", event)
                await onDelete(event)
            }
            finally {
                setDeleting(false)
            }
        }
    }

    function intervals() {
        const slots = []
        const start = moment('00:00', 'HH:mm');
        const end = moment('23:59', 'HH:mm');
        start.minutes(Math.ceil(start.minutes() / 15) * 15);
    
        var current = moment(start);
    
        while (current <= end) {
          if (slots.includes(current.format('HH:mm'))) {
            return null
          }
          else {
            slots.push(current.format('HH:mm'));
            current.add(15, 'minutes');
          }
        }
    
        return slots;
    }
    

    const timeSlots = intervals()

    const handleChangeTitle = async (val) => {
        // setTitle(val?.label ?? '')
        // setText(`Termin za ${val?.label.toUpperCase() || ''}`)

        // if(val?.id) {
        //     const info = await patientService.getPatientInfo(val.id);
        //     console.log("GOt patient info", info)
        //     setEmail(info.email)
        // }
        setTitle(val ?? '')
        setText(`Termin za ${val?.toUpperCase() || ''}`)


    }

    const handleChangeStartTime = (val) => {
        if(!val) {
            return ''
        }
        const start = moment(val, "HH:mm");
        const end = start.clone().add(30, 'minutes')
        setStartTime(start.format("HH:mm"))
        setEndTime(end.format("HH:mm"))
    }

    useEffect(() => {
        setFullScreen(size <= 576)
    }, [size])

    const handleClose = () => {
        clearState()
        if(props?.onClose) {
            props?.onClose()
        }
        
    }

    return (

        <Dialog open={props.open} onClose={handleClose} maxWidth={"lg"} fullWidth={true} fullScreen={fullScreen}>
            <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Termin</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Unesite termin za pacijenta
            </DialogContentText>
                {errors && <ul className='validation-errors'>{errors?.issues?.map(e => (<li>{e?.message}</li>))}</ul> || null}
                {/* {errors && <span style={{color:"red"}}>{errors}</span> || null} */}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl required  variant="filled" className="formControl">
                            <InputLabel id="calendar-id-label">Kalendar</InputLabel>
                            <Select
                                labelId="calendar-id-label"
                                id="calendarId"
                                value={calendarId}
                                onChange={(event) => setCalendarId(event.target.value)}
                            >
                                {calendarList.map(c => (
                                    <MenuItem value={c.id}>{c.name || c.summary || c.id}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <Autocomplete
                            id="title"
                            freeSolo
                            inline
                            options={patients}
                            value={title}
                            onChange={(event, newValue) => {
                                handleChangeTitle(newValue);
                            }}
                  
                            variant="filled"
                            fullWidth
                            renderInput={(params) => (
                                <TextField required {...params} label="Ime pacijenta" 
                                variant="outlined" />
                            )}
                        /> */}
                        <TextField
                            required
                            id="title"
                            label="Ime"
                            value={title}
                            onChange={(event) => handleChangeTitle(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
               
                    <Grid item xs={12}>
                        <TextField
                            id="email"
                            label="E-mail"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
              
                    <Grid item xs={12} md={2}>
                        <DatePicker
                            slotProps={{textField: {variant: 'outlined'}}}
              
                            ampm={false}
                            label="Datum"
                            value={startDate}
                            onChange={setStartDate}
                            clearable
                            format="L"
                            allowKeyboardControl
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            id="startTime"
                            inline
                            fullWidth
                            options={timeSlots}
                            value={startTime}
                            style={{ width: 200 }}
                            renderInput={(params) => (
                                <TextField {...params} fullWidth label="Početak" variant="outlined" />
                            )}
                            onChange={(_, value) => handleChangeStartTime(value) }
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            id="endTime"
                            inline
                            options={timeSlots}
                            value={endTime}
                            style={{ width: 200 }}
                            renderInput={(params) => (
                                <TextField {...params} label="Završetak" variant="outlined" />
                            )}
                            onChange={(_, value) => setEndTime(value)}
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <TextField
                            id="filled-multiline-flexible"
                            label="Opis"
                            multiline
                            value={text}
                            onChange={(event) => setText(event.target.value)}
                            rows={15}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>

            <Button type="submit" disabled={loading} style={{opacity: !loading ? 1 : 0.5}} disableElevation variant="contained" className="confirmButton">
                {loading ? "Spremam..." : "Spremi" }
            </Button>
            {event && event.id && (
            <Button variant="contained" disabled={deleting} style={{opacity: !deleting ? 1 : 0.5}} disableElevation onClick={handleDelete} className="dangerButton">
                {deleting ? "Brišem..." : "Obriši" }
            </Button>
            )}
 
            <Button variant="contained" disableElevation onClick={onClose}  className="defaultButton">
                Odustani
            </Button>
            </DialogActions>
            </form>
        </Dialog>
    )
}

export default CalendarEditor;