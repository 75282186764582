import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

const NotesEditor = ({note, formTitle, formSubtitle, onSubmit, onClose, ...props}) => {
    const [title, setTitle] = React.useState(null)
    const [text, setText] = React.useState(null)

    React.useEffect(() => {
        setText(null)
        setTitle(null)
    }, [])

    React.useEffect(() => {
        setText(note && note.text || null)
        setTitle(note && note.title || null)
    }, [note])

    const handleSubmit = () => {
        onSubmit({...note, text, title})
        setText(null)
        setTitle(null)
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth="lg" fullWidth="lg">
            <DialogTitle id="form-dialog-title">{formTitle || "Bilješka"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                {formSubtitle || note && "Uredite bilješku za pacijenta" || "Unesite bilješku za pacijenta"}
            </DialogContentText>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="title"
                            label="Naziv bilješke"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="filled-multiline-flexible"
                            label="Bilješka"
                            multiline
                            value={text}
                            onChange={(event) => setText(event.target.value)}
                            rows={15}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={onClose} color="primary">
                Odustani
            </Button>
            <Button onClick={handleSubmit} color="primary">
                Spremi
            </Button>
            </DialogActions>
        </Dialog>
    )
}

export default NotesEditor;