import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AboutSection from "../elements/about";
import LatestNewsSection from "../elements/latest-news-slider";
import FeatureSection3 from "../elements/feature-section3";
import TeamSection from "../elements/team";
import TestimonialSection from "../elements/testimonial";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import ptImg1 from "../../images/shap/wave-orange.png";
import ptImg2 from "../../images/shap/circle-small-blue.png";
import ptImg4 from "../../images/shap/square-dots-orange.png";
import ptImg5 from "../../images/shap/square-blue.png";

class Terms extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Uvjeti i pravila korištenja</h1>
                  {/* <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Početna
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        O Dent.ai
                      </li>
                    </ul> 
                  </nav>*/}
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>

          <section className="section-sp1 about-area">
            <div className="container">
              <div className="row align-items-center heading-bx">
                <div className="col-lg-12 mb-30">
                  <>
                    <p>
                      <em>Last Updated: 2024-01-01</em>
                    </p>

                    <h2>1. Sadržaj i opseg uvjeta uporabe</h2>
                    <p>
                      Prihvaćanje Uvjeta Korištenja Uvjeti i pravila uporabe
                      navedeni u nastavku uključujući politiku privatnosti
                      vrijede za sve korisnike, koji zaprime korisnički račun za
                      uporabu internetskih usluga Mirai Dent d.o.o. Nova pravila
                      uporabe počinju važiti za sve postojeće korisnike sa danom
                      25.5.2018 zbog stupanja na snagu nove europske regulative
                      s područja zaštite osobnih podataka. Korisnik prihvaća
                      Uvjete korištenja označavanjem odabira „Prihvaćam Uvjete
                      korištenja Mirai Dent d.o.o. i slažem se sa Pravilima
                      privatnosti“ tijekom procesa registracije. Prihvaćanjem
                      Uvjeta korištenja smatra se da je ugovor između korisnika
                      i Mirai Dent d.o.o. , a sklopljen elektroničkom obliku te
                      da je korisnik dao privolu za obradu osobnih podataka u
                      kontekstu GDPR uredbe. Korištenjem Mirai Dent d.o.o.
                      usluga korisnik se smatra upoznatim i kako razumije
                      aktualne Uvjete korištenja. Izmjene ovih Uvjeta korištenja
                      stupaju na snagu objavom na portalu i bez prethodne najave
                      korisnicima. Uvjeti korištenja dostupni su za spremanje i
                      ispis na Mirai Dent d.o.o. web stranicama. Ukoliko se
                      korisnik ne slaže s Uvjetima korištenja molimo da ne
                      koristi Mirai Dent d.o.o. usluge te da uputi zahtjev za
                      ostvarivanje njihovih prava iz GDPR uredbe (npr. uvid u
                      podatke koje Mirai Dent d.o.o. čuva korisniku, promjena i
                      brisanje podataka).
                    </p>

                    <h2>
                      2. Prihvaćanje uvjeta i pravila uporabe te početak važenja
                      ugovora
                    </h2>
                    <p>
                      Ukoliko nije drugačije dogovoreno pisanim putem, uvjeti i
                      pravila uporabe počinju važiti odmah po primitku potvrde o
                      registraciji za uporabu produkta ili pritiskom na
                      prihvaćanje uvjeta uporabe prilikom korisničke prijave u
                      produkt. Registracijom za uporabu produkta i pridobivanjem
                      korisničkog računa korisnik daje izričitu suglasnost da
                      davatelj usluge može obrađivati osobne podatke korisnika u
                      svrhu pružanja usluga, u skladu s uvjetima GDPR. Korisnik
                      izjavljuje, da je poslovno prisebna osoba te da je
                      sposoban biti nositelj prava i obveza iz ugovornog odnosa
                      s davateljem usluge. Korisnik je punoljetna osoba i
                      koristi produkt samostalno ili po ovlaštenju pretplatnika
                      isključivo u poslovne svrhe. Registraciju za uporabu
                      produkta može izvršiti samo punoljetna fizička osoba, koja
                      ima neograničene ovlasti za zastupanje poslovnog subjekta
                      (pretplatnika) u ime kojeg sklopi pretplatnički ugovor/ili
                      izvrši registraciju za uporabu produkta.
                    </p>

                    <h2>3. Mirai Dent Odgovornosti i odgovornosti korisnika</h2>
                    <p>
                      Mirai Dent d.o.o odgovornost: kao pružatelj Mirai Dent
                      d.o.o. aplikacija u kontekstu GDPR uredbe može se smatrati
                      izvršiteljem obrade. Mirai Dent d.o.o. aplikacije koriste
                      se direktno od strane korisnika i na njegov zahtjev putem
                      korisničkog sučelja a sama aplikacija provodi obradu
                      unesenih podataka. Mirai Dent d.o.o. implementira
                      odgovarajuće tehničke i organizacijske mjere u zaštiti
                      podataka te provodi osiguranje sigurnosti obrade,
                      raspoloživost sustava i usluge te mogućnost vraćanja
                      podataka u razumnom vremenu te redovito testiranje radi
                      osiguranja sigurnosti obrade. U samoj obradi podataka
                      Mirai Dent d.o.o. koristi druge izvršitelje obrade unutar
                      EU koji osiguravaju istu razinu sigurnosti i poštuju istu
                      GDPR regulativu, a koji obuhvaćaju spremište podataka
                      (npr. baza podataka i spremište datoteka) te servis za
                      slanje e-mail poruka iz aplikacija/sustava. Mirai Dent
                      d.o.o. kroz aplikacije omogućava svom korisniku kao
                      voditelju obrade da odgovori na zahtjeve svojih korisnika
                      (ispitanika) za ostvarivanje svojih prava (npr. uvid u
                      podatke koji se čuvaju o ispitaniku, promjena i brisanje
                      podataka). Ukoliko Mirai Dent d.o.o. korisnik nije u
                      mogućnosti u potpunosti ispuniti zahtjev ispitanika, Mirai
                      Dent d.o.o. će pomoći korisniku na njegov pismeni zahtjev.
                      Također, Mirai Dent d.o.o. će u okviru aplikacijske domene
                      koju korisnik komercijalno koristi i u okviru svojih
                      mogućnosti pomoći korisniku u ispunjavanju GDPR uredbe
                      (npr. informacije o Relagu kao izvršitelju obrade, pomoć u
                      definiranju zbirki podataka koje se obrađuju kroz Mirai
                      Dent d.o.o. aplikacije i sl.). Sve podatke koje korisnik
                      vodi u Mirai Dent d.o.o. aplikacijama Mirai Dent d.o.o.
                      smatra povjerljivim korisničkim podacima i nema uvid u te
                      podatke osim na pismeni zahtjev korisnika o čemu će Mirai
                      Dent d.o.o. voditi posebnu evidenciju. Mirai Dent d.o.o.
                      će kroz definirane procedure odgovoriti na zahtjeve svojih
                      korisnika za ostvarivanje njihovih prava iz GDPR uredbe
                      (npr. uvid u podatke koje Mirai Dent d.o.o. čuva
                      korisniku, promjena i brisanje podataka). Curenje podataka
                      je sigurnosni incident u kojem su osjetljivi, zaštićeni i
                      povjerljivi podaci kopirani, preneseni, pregledani,
                      ukradeni ili korišteni od strane neovlaštene osobe. U
                      slučaju da Mirai Dent d.o.o. otkrije curenje podataka bez
                      odgode će o tome informirati korisnika. Odgovornost
                      korisnika: Korisnik ima dužnost osigurati točne podatke u
                      svojoj registraciji i kasnije održavati te podatke ažurnim
                      i točnim kroz Moj Mirai Dent d.o.o. – Moji podaci.
                      Korisnik je odgovoran za sve aktivnosti koje su obavljene
                      pod korisničkim računom. Nije dopušteno da više osoba
                      dijeli isti korisnički račun. U besplatnoj probi korisnik
                      se savjetuje unositi samo testne podatke potrebne za
                      testiranje rada aplikacije. Nakon kupovine pretplate na
                      komercijalno korištenje aplikacije korisnik može unositi
                      realne podatke a ukoliko ne želi produžiti pretplatu dužan
                      je prije isteka pretplate izbrisati unesene podatke a
                      pogotovo one koji mogu biti sporni u smislu zaštite
                      podataka od strane izvršitelja obrade. Korisnik je dužan
                      zaštititi pristup i obradu svojih podataka koje pohranjuje
                      putem Mirai Dent d.o.o. aplikacija putem zaštite
                      pristupnih podataka (definiranje kompleksnih zaporki i
                      njezinih redovitih promjena), upravljanje pristupa
                      podacima odnosno aplikacijama svojim djelatnicima unutar
                      svoje organizacije (aktiviranje novih suradnika na radu u
                      aplikaciji te deaktiviranje suradnika koji više nemaju
                      pristupa aplikacijama) te ukoliko želi može koristiti
                      dodatne mogućnosti unutar aplikacija radi ograničavanja
                      pristupa određenim segmentima podataka i aplikacija
                      (koristeći uloge i ovlaštenja unutar aplikacija). U tom
                      smislu Mirai Dent d.o.o. nije odgovoran za štetu nastalu
                      zbog propusta u zaštiti podataka u domeni korisnika.
                      Korisnik je odgovoran za upoznavanje svih svojih
                      djelatnika koji koriste Mirai Dent d.o.o. usluge s
                      Uvjetima korištenja te osigurati njihovo korištenje Mirai
                      Dent d.o.o. usluga u skladu s funkcionalnostima i
                      ograničenjima probnih i kupljenih usluga. Korisnik će
                      uložiti razumne napore u sprječavanju neovlaštenog
                      pristupa i/ili korištenja Mirai Dent d.o.o. usluga te
                      promptno informirati Mirai Dent d.o.o. ukoliko sazna za
                      takve radnje. Korisnik je odgovoran za tajnost podataka o
                      svom korisničkom imenu i lozinki te ostalim privatnim
                      podacima. Mirai Dent d.o.o. ne snosi odgovornost za štetu
                      nastalu u slučaju neovlaštenog pristupa i korištenja Mirai
                      Dent d.o.o. usluga. Korisnik se obvezuje koristiti Mirai
                      Dent d.o.o. usluge za svoje interne poslovne potrebe i u
                      skladu s dobrom poslovnom praksom i relevantnim zakonima i
                      propisima. Korisniku nije dozvoljeno omogućavanje pristupa
                      i/ili korištenja Mirai Dent d.o.o. usluga za treće osobe,
                      osim za svoje djelatnike i korištenja svoje potrebe.
                      Korisnik je u potpunosti odgovoran za sadržaj koje unosi u
                      Mirai Dent d.o.o. usluge te za način na koji je došao do
                      tog sadržaja. Korisnik se slaže se da neće koristiti Mirai
                      Dent d.o.o. usluge za bilo kakve nelegalne ili sporne
                      aktivnosti koje narušavaju zakone i propise republike
                      Hrvatske te narušavaju ugled Mirai Dent d.o.o. usluga
                    </p>

                    <h2>4. Autorska Prava</h2>
                    <p>
                      Mirai Dent d.o.o. ima autorska prava na Mirai Dent d.o.o.
                      usluge te korištenjem Mirai Dent d.o.o. usluga korisnik ne
                      stječe pravo vlasništva nad Mirai Dent d.o.o. uslugama.
                      Kupovinom Mirai Dent d.o.o. usluga korisnik stječe pravo
                      korištenja Mirai Dent d.o.o. usluga u skladu s kupljenim
                      paketom i periodom pretplate. Korisnik nema pravo
                      koristiti Mirai Dent d.o.o. usluge kako bi stvorio nove
                      usluge bazirane na Mirai Dent d.o.o. uslugama osim uz
                      pismeno odobrenje Relaga. Korisnik ne smije provoditi
                      reverzno inženjerstvo Mirai Dent d.o.o. usluga te
                      pristupati Mirai Dent d.o.o. uslugama kako bi izradio
                      konkurentski proizvod/uslugu ili kopirati funkcionalnosti
                      i sadržaj Mirai Dent d.o.o. usluga. Mirai Dent d.o.o. ima
                      pravo navesti podatke i vizualni identitet (logotip)
                      korisnika na Mirai Dent d.o.o. web stranicama jedino i
                      isključivo u svrhu pružanja informacija o referentnom
                      popisu korisnika Mirai Dent d.o.o. usluga.
                    </p>

                    <h2>5. Privatnost i zaštita podataka</h2>
                    <p>
                      Davatelj usluge će prikupljati, obrađivati i pohranjivati
                      samo one osobne podatke korisnika, koji su neophodni za
                      rad produkta i usluga i/ili su nužni za vođenje zakonom
                      propisanih evidencija. Davatelj usluge će tretirati osobne
                      podatke povjerljivo i u skladu s važećim zakonima. Osobne
                      podatke neće otkrivati trećoj osobi, osim ako je to
                      određeno ugovorom ili drugom zakonskom odlukom o
                      raskrivanju podataka (npr. sudska presuda). Davatelj
                      produkta i usluga će obrađivati podatke isključivo u
                      skladu s uputama korisnika tj. pretplatnika. U ugovornom
                      odnosu između davatelja usluge i pretplatnika, pretplatnik
                      se smatra isključivim vlasnikom svih podataka koje
                      proslijeđuje davatelju produkta i usluga. Produkt
                      pretplatniku omogućuje da samostalno izvrši izvoz svih
                      podataka, koji se nalaze na poslužitelju davatelja usluge,
                      u digitalnom obliku. Pretplatnik je dužan prije prekida
                      pretplatničkog tj. drugog ugovornog odnosa izvoziti sve
                      svoje podatke sa poslužitelja davatelja usluge. Davatelj
                      usluge će informacije o obradi podataka dati samo u mjeri
                      koja je nužna za zakonito pružanje usluga i/ili su
                      zahtjevani od strane službenih organa., Davatelj usluge će
                      strogo štititi povjerljivost svih podataka pretplatnika,
                      koje korisnik tj. pretplatnik proslijeđuje davatelju
                      usluge, osim u slučaju kada bi ti podaci izašli u javnost
                      od strane treće osobe. U slučaju da davatelj usluge
                      zaprimi zahtjev za proslijeđivanje podataka pretplatnika
                      od strane istražnog tijela ili suda, davatelj usluge će po
                      prethodnoj provjeri pravne utemeljenosti zahtjeva
                      proslijediti podatke u minimalnom opsegu nužnom za
                      ispunjavanje zahtjeva i o tome obavjestiti pretplatnika.
                    </p>

                    <h2>6. GDPR i dent.AI aplikacija</h2>
                    <p>
                      Stupanjem na snagu Opće uredbe o zaštiti podataka
                      (poznatija kao GDPR), tvrtke paziti na obradu osobnih
                      podataka.Naime, u Zakonu o radu i Pravilniku o sadržaju i
                      načinu vođenja evidencije o radnicima propisana je obveza
                      poslodavaca za vođenjem evidencije. Osim toga, taksativno
                      su navedene i kategorije osobnih podataka koje poslodavac
                      mora prikupljati od svojih zaposlenika. Upravo zato,
                      poslodavci moraju paziti da ne prikupljaju više
                      informacija no što je to zakonski definirano.MiraiDent
                      d.o.o je svoj sustav za evidenciju radnog vremena i
                      kontrolu pristupa "dent.AI aplikacija" uskladila s
                      odredbama GDPR-a. Davatelj usluge će informacije o obradi
                      podataka dati samo u mjeri koja je nužna za zakonito
                      pružanje usluga i/ili su zahtjevani od strane službenih
                      organa., Davatelj usluge će strogo štititi povjerljivost
                      svih podataka pretplatnika, koje korisnik tj. pretplatnik
                      proslijeđuje davatelju usluge, osim u slučaju kada bi ti
                      podaci izašli u javnost od strane treće osobe. U slučaju
                      da davatelj usluge zaprimi zahtjev za proslijeđivanje
                      podataka pretplatnika od strane istražnog tijela ili suda,
                      davatelj usluge će po prethodnoj provjeri pravne
                      utemeljenosti zahtjeva proslijediti podatke u minimalnom
                      opsegu nužnom za ispunjavanje zahtjeva i o tome
                      obavjestiti pretplatnika.
                    </p>
                  </>
                </div>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
            <img className="pt-img2 animate2" src={ptImg2} alt="" />
            <img className="pt-img3 animate-rotate" src={ptImg5} alt="" />
            <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
            <img className="pt-img5 animate2" src={ptImg5} alt="" />
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Terms;
