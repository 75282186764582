import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import './CalendarSettings.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import withStyles from '@mui/styles/withStyles';

import { CirclePicker   } from 'react-color';
import { calendarService } from '../../services/calendar.service';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import "../../App.css"

const StyledTableCell = withStyles((theme) => ({
    
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        cursor: 'pointer',
        '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const CalendarSettings = ({onSubmit, onDelete, onClose, formTitle, ...props}) => {

    const [calendars, setCalendars] = useState([])
    const [showCalendarEditor, setShowCalendarEditor] = useState(false)
    const [selectedCalendar, setSelectedCalendar] = useState(null)

    const fontSizes = {
        "xx-small": "XX-Mala",
        "x-small": "X-Mala",
        "small": "Mala",
        "smaller": "Manja",
        "medium": "Srednja",
        "larger": "Veća",
        "large": "Velika",
        "x-large": "X-Velika",
        "xx-large": "XX-Velika",
    }

    React.useEffect(() => {
        if(props.open) {
            const fetchData = async () => {
                const calendarList = await calendarService.getCalendars();
                
                setCalendars(calendarList)
            }
            fetchData()
        }

    }, [props.open])


    const toggleCalendarEditor = () => {
        setShowCalendarEditor(!showCalendarEditor)
    }

    const handleConfirm = () => {
        onSubmit()
        
    }

    const handleDelete = (row) => async () => {
        console.log("Delete calendar:", row)
        if(window.confirm('Jeste li sigurni da želite obrisati ovaj kalendar?')) {
            const newCalendars = calendars.filter(c => c.id != row.id)
            try {
                await calendarService.saveCalendarList(newCalendars)
                toggleCalendarEditor()
                setCalendars(newCalendars)
                toggleCalendarEditor()
            }
            catch(error) {
                console.error("Error while saving calendar list:", error)
                alert("Dogodila se pogreška prilikom brisanja kalendara")
            }
        }
    }

    const handleEdit = (row) => () => {
        console.log("Edit data:", row)
        setSelectedCalendar(row)
        toggleCalendarEditor()
    }

    const handleAddNewCalendar = () => {
        setSelectedCalendar({})
        toggleCalendarEditor()
    }

    const handleAddEditCalendar = async (event) => {
        event.preventDefault();
        const form = new FormData(event.target);
		const data = Object.fromEntries(form.entries()); 
        
        let dataToSave = null;
        if(selectedCalendar.id) {
            dataToSave = [...calendars];
            const index = dataToSave.findIndex(d => d.id == selectedCalendar.id)
            dataToSave[parseInt(index)] = selectedCalendar;
            setCalendars(dataToSave)
        }
        else {
            dataToSave = [...calendars, selectedCalendar]
            setCalendars(dataToSave)
        }

        try {
            console.log("Save calendar:", selectedCalendar, dataToSave)
            await calendarService.saveCalendarList(dataToSave)
            toggleCalendarEditor()
        }
        catch(error) {
            console.error("Error while saving calendar list:", error?.response)
            alert("Dogodila se pogreška prilikom spremanja kalendara")
        }
    }

    const handleChange = evt => {
        const name = evt.target.name;
        const value =
        evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setSelectedCalendar({
          ...selectedCalendar,
          [name]: value
        })
    }

    const getColor = (rgb) => {
        const color = Math.round(((parseInt(rgb.r) * 299) +
            (parseInt(rgb.g) * 587) +
            (parseInt(rgb.b) * 114)) / 1000);
        const textColor = (color > 125) ? 'black' : 'white';
        
        return textColor;
    }

    const handleChangeColor = (color) => {
        const textColor = getColor(color.rgb)
      
        setSelectedCalendar({
            ...selectedCalendar,
            backgroundColor: color.hex,
            color: textColor
        })
    }

    return (<>

        <Dialog open={props.open} onClose={props.onClose} maxWidth="lg" fullWidth="lg">
            <DialogTitle id="form-dialog-title">{formTitle || ''}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                <div>Posložite postavke kalendara kao što su boja i sl.</div>
                {/* <div className='calendar-add'>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddNewCalendar}
                >
                    Dodaj novi kalendar
                </Button>
                </div> */}
 
            </DialogContentText>
                
                <br/>
                <TableContainer component={Paper}>
                    <Table className="table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>Naziv</StyledTableCell>
                            <StyledTableCell>Oznaka</StyledTableCell>
                            
                            <StyledTableCell>Boja</StyledTableCell>
                            <StyledTableCell>Veličina teksta</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {calendars.map((row, i) => (
                            <StyledTableRow key={row.name} onClick={handleEdit({rowIndex: i.toString(), ...row})}>
                                 <StyledTableCell>
                                    {row.name || row.summary}
                                </StyledTableCell>
                                <StyledTableCell width="30%">{row.id}</StyledTableCell>
                               
                                <StyledTableCell align="center"><div style={{backgroundColor: row.backgroundColor || 'transparent', width: '32px', height: '32px'}}></div></StyledTableCell>
                                <StyledTableCell align="left">{row.fontSize && fontSizes[row.fontSize] || 'Srednja'}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
               
            </DialogContent>
            <DialogActions>

                <Button variant="contained" onClick={handleConfirm} className="confirmButton">
                    Potvrdi
                </Button>

                <Button variant="contained" onClick={onClose}  className="defaultButton">
                    Odustani
                </Button>
            </DialogActions>
   
        </Dialog>

        <Dialog open={showCalendarEditor} onClose={toggleCalendarEditor} maxWidth="md" fullWidth="md">
            <form onSubmit={handleAddEditCalendar} noValidate autoComplete="off">
                
                <DialogTitle id="form-dialog-title">{formTitle || ''}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField name="id" fullWidth variant="outlined" label="Oznaka" value={selectedCalendar?.id || null} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="summary" fullWidth variant="outlined" label="Naziv" value={selectedCalendar?.summary || null} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Veličina</InputLabel>
                                <Select name="fontSize"  value={selectedCalendar?.fontSize || 'medium'} onChange={handleChange}>
                                    <MenuItem value={"xx-small"}>XX-Mala</MenuItem>
                                    <MenuItem value={"x-small"}>X-Mala</MenuItem>
                                    <MenuItem value={"small"}>Mala</MenuItem>
                                    <MenuItem value={"smaller"}>Manja</MenuItem>
                                    <MenuItem value={"medium"}>Srednja</MenuItem>
                                    <MenuItem value={"larger"}>Veća</MenuItem>
                                    <MenuItem value={"large"}>Velika</MenuItem>
                                    <MenuItem value={"x-large"}>X-Velika</MenuItem>
                                    <MenuItem value={"xx-large"}>XX-Velika</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <label>Boja</label>
                            <CirclePicker  
                                color={ selectedCalendar?.backgroundColor || 'transparent' }
                                onChangeComplete={handleChangeColor }
                            />
                   
                        </Grid>
            
                    </Grid>
                </DialogContent>
                <DialogActions>

                    <Button variant="contained" type="submit" className="confirmButton">
                        Spremi
                    </Button>
                    {selectedCalendar && selectedCalendar.id && (
                        <Button variant="contained" onClick={handleDelete(selectedCalendar)} className="dangerButton">
                            Obriši
                        </Button>
                    ) || null} 
                  
                    <Button variant="contained" onClick={toggleCalendarEditor}  className="defaultButton">
                        Odustani
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
       

    </>)
}

export default CalendarSettings;