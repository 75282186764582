import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";

import { patientService } from "../../services";
import "./Patients.css";

import AppLogo from "../../components/AppLogo";
import Profile from "../../components/Profile";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Appointments from "../Dashboard/Appointments";
import NavButton from "../../components/NavButton";
import DateRangeIcon from "@mui/icons-material/DateRange";
import GroupIcon from "@mui/icons-material/Group";
import PatientEditor from "../../components/PatientEditor";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import Fab from "@mui/material/Fab";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CalendarSettings from "../../components/CalendarSettings";
import { AuthContext } from "../../context/AuthContext";
//import {useGoogle} from '../../hooks';
import { usePatients } from "../../hooks/usePatients";
import "../../App.css";
import { styled } from "@mui/material/styles";
import { ListItemButton } from "@mui/material";

const AbcButton = styled(Button)(({ theme }) => ({
  color: "#7A3D79",
  borderRadius: "25px",
  boxShadow: "none",
  padding: "3rem",
  fontSize: "36px",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#7A3D79",
    color: "#fff",
  },
  "&:focus": {
    backgroundColor: "#7A3D79",
    color: "#fff",
    boxShadow: "0 10px 40px 0 rgba(122, 61, 121, 0.5)",
  },
  "&:active": {
    backgroundColor: "#7A3D79",
    color: "#fff",
    boxShadow: "0 10px 40px 0 rgba(122, 61, 121, 0.5)",
  },
}));

const PatientList = ({ selectedView, ...props }) => {
  const { user, token, userData } = useContext(AuthContext);

  const VIEW_PATIENTS = 1;
  const VIEW_CALENDAR = 2;

  const [patients, setPatients] = React.useState([]);
  const [patientLetters, setPatientLetters] = React.useState(null);
  const [showDialog, setShowDialog] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [view, setView] = useState(selectedView || VIEW_PATIENTS);
  const [showPatientEditor, setShowPatientEditor] = useState(false);
  const [showCalendarSettings, setShowCalendarSettings] = useState(false);

  const {
    data: patientList,
    error: errorPatients,
    isLoading: loading,
    mutate: mutatePatients,
  } = usePatients();

  useEffect(() => {
    const fetchData = async () => {
      if (patientList?.length) {
        const folders = patientList;
        const letters = [
          ...new Set(
            folders?.map((p) => {
              const name = p.name.split("_")[0].toUpperCase();
              if (name.startsWith("LJ")) {
                return "LJ";
              }

              if (name.startsWith("NJ")) {
                return "NJ";
              }

              return name[0];
            })
          ),
        ].sort((a, b) => a.localeCompare(b, "hr", { ignorePunctuation: true }));

        const patients = await Promise.all(
          folders.map((f) =>
            patientService.getPatientInfo(f.id).catch((err) => {
              console.error(err);
              return f;
            })
          )
        );

        setPatientLetters(letters || []);
        setPatients(patients);
      }
    };

    fetchData();
  }, [patientList]);

  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        mutatePatients();
      };

      fetchData();
    }
  }, [token]);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const handleLetterClick = (letter) => {
    if (letter) {
      let data = patients.filter((x) =>
        x.name?.toUpperCase().startsWith(letter)
      );

      if (letter === "L") {
        data = data.filter((x) => !x.name?.toUpperCase().startsWith("LJ"));
      }
      if (letter === "N") {
        data = data.filter((x) => !x.name?.toUpperCase().startsWith("NJ"));
      }

      setSelectedItems(data);
      toggleDialog();
    }
  };

  const togglePatientEditor = () => {
    setShowPatientEditor(!showPatientEditor);
  };

  const toggleCalendarSettings = () => {
    setShowCalendarSettings(!showCalendarSettings);
  };

  const handleAddPatient = async (patient) => {
    if (!patient.name) {
      alert("Morate unesti ime pacijenta!");
      return;
    }

    try {
      await patientService.addPatient(patient);
      // await getPatients()
      mutatePatients();
      togglePatientEditor();
    } catch (error) {
      console.error("Error while creating patient:", error);
      alert("Dogodila se pogreška prilikom kreiranja pacijenta!");
    }
  };

  const handleEditCalendarSettings = () => {
    setView(VIEW_PATIENTS);
    toggleCalendarSettings();
  };

  return (
    <div className="page-wrap">
      <Header className="start-screen-header">
        <div className="patients-wrapper">
          <AppLogo />
          <div
            className="patients-header"
            style={{ gridTemplateColumns: "minmax(10px, 1fr) !important" }}
          >
            <Profile />
            <h1 className="patients-header-text">
              <span className="welcome-text">Dobrodošli, </span>{" "}
              <span className="clinic-name">
                {userData?.displayName ?? user?.displayName ?? "DENT.AI"}
              </span>
              <div className="clinic-description thin">
                {userData?.subtitle ??
                  user?.subtitle ??
                  "ORDINACIJA DENTALNE MEDICINE"}
              </div>
            </h1>
          </div>
        </div>
        <div className="action">
          <NavButton
            className="action__add"
            variant="contained"
            size="large"
            startIcon={<GroupAddIcon />}
            color="primary"
            onClick={togglePatientEditor}
          >
            Dodaj
          </NavButton>
          <NavButton
            variant="contained"
            size="large"
            className={(view == VIEW_PATIENTS && "selected") || "button"}
            startIcon={<GroupIcon />}
            color="primary"
            style={{ marginRight: "0.5rem" }}
            onClick={() => setView(VIEW_PATIENTS)}
          >
            Pacijenti
          </NavButton>
          <NavButton
            variant="contained"
            size="large"
            className={(view == VIEW_CALENDAR && "selected") || "button"}
            startIcon={<DateRangeIcon />}
            color="primary"
            style={{ marginRight: "0.5rem" }}
            onClick={() => setView(VIEW_CALENDAR)}
          >
            Kalendar
          </NavButton>
        </div>
      </Header>

      <main className="patients-main">
        <div>
          {(view === VIEW_PATIENTS && (
            <div>
              <h2 className="page-title">Pacijenti</h2>
              {(loading && (
                <div
                  style={{
                    display: "flex",
                    height: "calc(50vh - 30px)",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress color="secondary" />
                </div>
              )) ||
                null}
              {!loading && (
                <div className="content-wrapper">
                  {(patientLetters &&
                    patientLetters.length &&
                    patientLetters.map((p) => (
                      <AbcButton
                        key={p}
                        className="button"
                        onClick={() => handleLetterClick(p)}
                      >
                        {p}
                      </AbcButton>
                    ))) ||
                    null}
                </div>
              )}
            </div>
          )) ||
            (view === VIEW_CALENDAR && (
              <div>
                <h2 className="page-title">
                  Kalendar
                  <Fab
                    color="primary"
                    aria-label="Postavke kalendara"
                    style={{ marginLeft: "1rem" }}
                    onClick={toggleCalendarSettings}
                  >
                    <SettingsIcon />
                  </Fab>
                </h2>
                <Appointments />
              </div>
            )) ||
            null}
        </div>
      </main>

      <Dialog
        open={showDialog}
        onClose={toggleDialog}
        fullWidth
        maxWidth="md"
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Popis pacijenata</DialogTitle>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description">
            <List component="nav" aria-label="secondary mailbox folders">
              {(selectedItems &&
                selectedItems.map((item, i) => (
                  <Link
                    to={{
                      pathname: "/patients/dashboard",
                      state: { patient: item },
                    }}
                  >
                    <ListItemButton>
                      <ListItemText
                        primary={`${i + 1}. ${item.name.replace("_", " ")}`}
                      />
                    </ListItemButton>
                  </Link>
                ))) ||
                null}
            </List>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <PatientEditor
        open={showPatientEditor}
        formTitle="Dodaj pacijenta"
        onClose={togglePatientEditor}
        onSubmit={handleAddPatient}
      />

      <CalendarSettings
        open={showCalendarSettings}
        formTitle="Postavke kalendara"
        onClose={toggleCalendarSettings}
        onSubmit={handleEditCalendarSettings}
      />

      <footer className="page-footer"></footer>
    </div>
  );
};

export default PatientList;
