import React, { useEffect, useState } from "react";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import googleDriveService from "../../../services/google-drive.service";

import "./ImagesWidget.css";
import AsyncImage from "../../AsyncImage";
import { blobToBase64 } from "../../../helpers";

const ImagesWidget = ({
  title,
  images,
  onClick,
  onImageClick,
  onDelete,
  className,
  ...props
}) => {
  const [page, setPage] = useState(1);

  const pageSize = 16;

  const totalPages =
    (images &&
      images.length && [...Array(Math.round(images.length / pageSize) + 1)]) ||
    null;

  const handleLoadImage = async (imageId) => {
    const objectUrl = await googleDriveService.getImage(imageId);
    return blobToBase64(objectUrl.data);
  };

  const translated = title === "Images" ? "Slike" : title;

  const handleClickedImage = (imageId, imageIndex) => {
    onClick(imageId, imageIndex);
  };

  const handleDeleteImage = async (imageId, imageName) => {
    if(window.confirm(`Jeste li sigurni da želite obrisati sliku ${imageName}?`)) {
      onDelete(imageId)
      
    }
  }

  return (
    <div className={`img-widget ${className}`}>
      <h3>{translated}</h3>
      <div className="img-images">
        {images &&
          images.length &&
          images.slice(page - 1 * pageSize, pageSize).map((image, i) => {
            if (image.mimeType.includes("video")) {
              return (
                <div>
                  <a
                    rel="noreferrer"
                    key={i}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      overflow: "hidden",
                    }}
                    className={`image ${
                      (i > 3 && "small-image") || "large-image"
                    }`}
                    href={image.webViewLink}
                    target="_blank"
                  >
                    <PlayCircleFilledWhiteIcon fontSize="large" />
                  </a>
                  <div className="video-title-background">
                    <span className="video-title">{image.name}</span>
                  </div>
                </div>
              );
            }

            return (
              <>
                <AsyncImage
                  key={i}
                  imageId={image.id}
                  className={`image ${
                    (i > 3 && "small-image") || "large-image"
                  }`}
                  onClick={() => handleClickedImage(image.id, i)}
                  handleLoad={() => handleLoadImage(image.id)}
                  onDelete={(imgId) => handleDeleteImage(image.id, image.name)}
                />
              </>
            );
          })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "5px",
          gap: "0.5rem",
        }}
      >
        {totalPages &&
          totalPages.length > 1 &&
          totalPages.map((e, i) => (
            <div
              style={{
                background: "#fff",
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                opacity: (i + 1 == page && "1") || "0.6",
              }}
              key={i}
              onClick={() => setPage(i + 1)}
            >
              &nbsp;
            </div>
          ))}
      </div>
    </div>
  );
};

export default ImagesWidget;
