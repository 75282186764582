import axios from 'axios';
import AppConfig from '../config/AppConfig';

async function list(url) {
    return await axios.get(encodeURI(`https://content.googleapis.com/drive/v3/files?${url}`))
}

async function create(data) {
    return await axios.post('https://www.googleapis.com/drive/v3/files', data)
}

async function update(fileId, params) {
    //const url = Object.keys(params).map(key => key + '=' + params[key]).join('&')

    return await axios.patch(encodeURI(`https://www.googleapis.com/drive/v3/files/${fileId}?${params}`), null)
    
}

async function getImage(imageId) {
    //const url = Object.keys(params).map(key => key + '=' + params[key]).join('&')

    //return await axios.get(fileLink)
    return await axios.get(encodeURI(`https://content.googleapis.com/drive/v3/files/${imageId}?alt=media`), { responseType: 'blob'})

}


async function deleteFile(fileId, params) {
    //const url = Object.keys(params).map(key => key + '=' + params[key]).join('&')
    return await axios.patch(encodeURI(`https://www.googleapis.com/drive/v3/files/${fileId}`), {'trashed': true})
}



async function uploadAttachment(token, folderId, attachment) {
    return await axios.post(`${AppConfig.apiUrl}/sync`, {token, folderId, attachment})

    //const file = await base64Response.blob();

    //const base64Response = await fetch(attachment.data);

    //var file = await base64Response.blob();
    var metadata = {
        "name": attachment.fileName,
        "mimeType": attachment.mimeType,
        "parents": [folderId], // Google Drive folder id
    };

    // console.log("Got file", file)
    // var form = new FormData();
    // form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    // form.append('file', file);

    // return await axios({
    //     method: 'post',
    //     url: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true',
    //     data: form,
    //     headers: {
    //         'Content-Type': `multipart/form-data`,
    //     },
    // });

    // return await axios.post()
    // fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true", {
    //     method: 'POST',
    //     headers: new Headers({ 'Authorization': 'Bearer ' + accessToken }),
    //     body: form,
    // }).then((res) => {
    //     return res.json();
    // }).then(function(val) {
    //     console.log(val);
    // });
}

async function getThumbnailImage(thumbnailLink) {
    //const url = Object.keys(params).map(key => key + '=' + params[key]).join('&')

    //return await axios.get(fileLink)
    return await axios.get(encodeURI(thumbnailLink), { responseType: 'blob'})

}

export default {
    list,
    create,
    update,
    getImage,
    deleteFile,
    uploadAttachment,
    getThumbnailImage
}