import React, { useContext } from "react";
import Button from '@mui/material/Button';
import { AuthContext } from "../../context/AuthContext";
import "./NeedApproval.css"

import Paper from '@mui/material/Paper';
import { emailService } from "../../services/email.service";

const NeedApproval = ({googleLogin}) => {
    const {invalidSession, setInvalidSession, user} = useContext(AuthContext)

    const handleSendActivationRequest = async() => {
        try {
            await emailService.sendServiceActivationRequest(user)
            alert("Uspješno ste poslali zahtjev za aktivaciju usluge, kontaktirati ćemo vas putem vaše e-mail adrese!")
        }
        catch(error) {
            console.log("Error while sending activation request", error)
            alert("Dogodila se pogreška prilikom slanja zahtjeva za aktivaciju usluge, molimo pokušajte ponovno")
        }
    }

    return (<div className="authorize-backdrop">


       <Paper style={{padding: '2rem 3rem', display: 'flex', alignItems: 'stretch', flexDirection: 'column', justifyContent: 'center'}}>
        <h3>

        {invalidSession && 
            "Istekla vam je sesija, morate ponovno dozvoliti pristup Google uslugama"
        || "Aktivirajte dent.ai"}
        </h3>
       <Button variant="contained" style={{background: '#7A3D79', marginTop: '0.5rem', boxShadow: '0 10px 40px 0 rgb(122 61 121 / 50%)'}} color="primary" onClick={handleSendActivationRequest}>
            Zatražite pristup aplikaciji
        </Button>
       </Paper>
 
    </div>)
}

export default NeedApproval;