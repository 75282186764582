import React, { Component } from "react";
import { Link } from "react-router-dom";

// Images
import Logo from "../../images/dent-ai-logo.png";
import footerBg from "../../images/background/footer.jpg";
import ptImg1 from "../../images/shap/wave-blue.png";
import ptImg2 from "../../images/shap/circle-dots.png";
import ptImg3 from "../../images/shap/plus-blue.png";
import ptImg4 from "../../images/shap/wave-blue.png";

// Social Images
import facebook from "../../images/social/facebook.png";
import twitter from "../../images/social/twitter.png";
import instagram from "../../images/social/instagram.png";
import linkedin from "../../images/social/linkedin.png";

class aboutSection extends Component {
  render() {
    return (
      <>
        <footer
          className="footer"
          style={{ backgroundImage: "url(" + footerBg + ")" }}
        >
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="widget widget_info">
                    <div className="footer-logo">
                      <Link to="/">
                        <img src={Logo} alt="" />
                      </Link>
                    </div>
                    <div className="ft-contact">
                      <p>
                        Pametni asistent koji za Vas samostalno prikuplja,
                        sistematizira i organizira medijske sadržaje i podatke o
                        pacijentu (RTG snimke, fotografije, video zapisi i sl.)
                        te ih objedinjuje na jednome mjestu.
                      </p>
                      <div className="contact-bx">
                        <div className="icon">
                          <i className="fas fa-envelope"></i>
                        </div>
                        <div className="contact-number">
                          <span>Kontaktirajte nas</span>
                          <h4 className="number">
                            <a href="mailto:info@miraident.hr">
                              Zatražite demo
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-6">
                  <div className="widget footer_widget ml-50">
                    <h3 className="footer-title">Brze poveznice</h3>
                    <ul>
                      <li>
                        <Link to="/about-us">
                          <span>O nama</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/terms">
                          <span>Uvjeti i pravila korištenja</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/pricing">
                          <span>Cjenik</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/login">
                          <span>Prijava</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="widget widget_form">
                    <h3 className="footer-title">Društvene mreže</h3>

                    <div className="footer-social-link">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/profile.php?id=61558793041711"
                          >
                            <img src={facebook} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://twitter.com/"
                          >
                            <img src={twitter} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/"
                          >
                            <img src={instagram} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/"
                          >
                            <img src={linkedin} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="footer-bottom">
              <div className="row">
                <div className="col-12 text-center">
                  <p className="copyright-text">
                    Copyright © 2024 Design & Developed by{" "}
                    <a
                      href="https://dent-ai.app"
                      rel="noreferrer"
                      target="_blank"
                      className="text-secondary"
                    >
                      Mirai Dent d.o.o
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
          <img className="pt-img2 animate1" src={ptImg2} alt="" />
          <img className="pt-img3 animate-rotate" src={ptImg3} alt="" />
          <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
        </footer>
      </>
    );
  }
}

export default aboutSection;
