import { useContext, useEffect } from 'react';
import { signInWithCredential } from 'firebase/auth';
import { signInWithGoogle, auth, app } from '../../services/firebase.service';
import { GoogleAuthProvider } from 'firebase/auth';
import { AuthContext } from '../../context/AuthContext';
import { useHistory, useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import AppConfig from '../../config/AppConfig';
import './Login.css'
import AppLogo from '../../components/AppLogo';
import Paper from '@mui/material/Paper';

const LoginPage = () => {
    let history = useHistory();
    let location = useLocation();
    const { setToken, setGoogleClient } = useContext(AuthContext);

    const handleCredentialResponse = (response) => {
        console.log("Got credentials response:", response)
        const credential = GoogleAuthProvider.credential(response.credential);
        signInWithCredential(auth, credential)

        let { from } = location.state || { from: { pathname: "/patients" } };
        history.replace(from);
    }

    return (
        <>
        <div className="login-backdrop">
            <Paper style={{padding: '2rem 3rem', display: 'flex', alignItems: 'stretch', flexDirection: 'column', justifyContent: 'center'}}>
                <AppLogo hideBack={true}/>
                <GoogleLogin
                    auto_select
                    clientId={AppConfig.clientId}
                    onSuccess={handleCredentialResponse}
                    scope={AppConfig.scope}
                    discoveryDocs={AppConfig.discoveryDocs}
                    isSignedIn={true}
                />
            </Paper>
        </div>
       
     
        </>
    )
}

export default LoginPage;