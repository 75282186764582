export default {
  apiUrl:
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
      ? "http://localhost:4082/dent-ai/api"
      : "https://busyeasyit.hr/dent-ai/api",
  clientId:
    "38500742429-bbp90ig55cmh4tc6pjictpmjv4gietce.apps.googleusercontent.com",
  scope:
    "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/gmail.readonly",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
  tokenStorage: "drive-token-oauth",
};
