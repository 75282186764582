import Logo from '../Logo';
import './AppLogo.css';
import Profile from '../Profile';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

const AppLogo = ({children, hideBack, ...props}) => {
    const {user, userData} = useContext(AuthContext)
    const history = useHistory();

    const goBack = () => {
        let { from } = { from: { pathname: "/patients" } };
        history.replace(from);
    }


    return (
        <div className="app-logo">
            {!hideBack && (
         <div>
         <IconButton
             color="secondary"
             className="back-button__mobile"
             onClick={goBack}
             size="large">
             <ArrowBackIosIcon />
         </IconButton>
     </div>
            ) || null}
   
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem'}}>
                <Logo style={{width:'200px'}} /> 
                <div className="app-logo__profile">
                   
                    <span className="app-logo__username"><span>{userData?.displayName ?? user?.displayName}</span></span>
                </div>
            </div>
            
            <Profile mobile={true} />
        </div>
    );
}

export default AppLogo;