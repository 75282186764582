import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { patientService } from '../../services/patient.service';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    defaultButton: {
        margin: theme.spacing(1),
        backgroundColor: "#e0e0e0",
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: '600',
        fontSize: '1.4rem',
        padding: '0.5rem 2rem',
        '&:hover': {
            backgroundColor: "#eeeeee",
            //boxShadow: "0 10px 40px 0 rgba(189, 189, 189, 0.8)",
        },
        //boxShadow: "0 10px 40px 0 rgba(189, 189, 189, 0.5)",
    },
    confirmButton: {
        margin: theme.spacing(1),
        backgroundColor: "#8bc34a",
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: '600',
        fontSize: '1.4rem',
        padding: '0.5rem 2rem',
        '&:hover': {
            backgroundColor: "#a2cf6e",
            //boxShadow: "0 10px 40px 0 rgba(97, 136, 51, 0.8)",
        },
        //boxShadow: "0 10px 40px 0 rgba(97, 136, 51, 0.5)",
    },
    dangerButton: {
        margin: theme.spacing(1),
        backgroundColor: "#e53935",
        color: "#fff",
        fontWeight: '600',
        fontSize: '1.4rem',
        padding: '0.5rem 2rem',
        '&:hover': {
            backgroundColor: "#f44336",
            //boxShadow: "0 10px 40px 0 rgba(211, 47, 47, 0.8)",
        },
        //boxShadow: "0 10px 40px 0 rgba(211, 47, 47, 0.5)",
    },
}));

const PatientEditor = ({open, patient, formTitle, formSubtitle, onSubmit, onClose, ...props}) => {
    const classes = useStyles();
    const history = useHistory()
    const [patientName, setPatientName] = React.useState('')
    const [patientEmail, setPatientEmail] = React.useState('')
    const [patientContactNumber, setPatientContactNumber] = React.useState('')
    const [patientAddress, setPatientAddress] = React.useState('')
    const [patientOther, setPatientOther] = React.useState('')
    const [saving, setSaving] = useState(false)

    const clearData = () => {
        setPatientName('')
        setPatientEmail('')
        setPatientContactNumber('')
        setPatientAddress('')
        setPatientOther('')
    }

    React.useEffect(() => {
        clearData();
    }, [])

    React.useEffect(() => {
        if(patient) {
            setPatientName(patient && patient.name || '')
            setPatientEmail(patient && patient.email || '')
            setPatientContactNumber(patient && patient.contactNumber || '')
            setPatientAddress(patient && patient.address || '')
            setPatientOther(patient && patient.other || '')
        }
    }, [patient])

    const handleSubmit = () => {
        onSubmit({...patient, name: patientName, email: patientEmail, contactNumber: patientContactNumber, address: patientAddress, other: patientOther})
        clearData();
    }

    const handleDelete = async () => {
        if(patient) {
            try {
                setSaving(true)
                await patientService.deletePatient(patient?.id)
                let { from } = { from: { pathname: "/" } };
                history.replace(from);
            }
            finally {
                setSaving(false)
            }
        }
    }

    return (
        <Dialog open={open} onClose={props.onClose} maxWidth="lg" fullWidth="lg">
            <DialogTitle id="form-dialog-title">{formTitle || "Uređivanje podataka o pacijentu"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                {formSubtitle || "Unesite podatke pacijenta"}
            </DialogContentText>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Naziv"
                            value={patientName}
                            onChange={(event) => setPatientName(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="E-mail"
                            value={patientEmail}
                            onChange={(event) => setPatientEmail(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Kontakt broj"
                            value={patientContactNumber}
                            onChange={(event) => setPatientContactNumber(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Adresa"
                            value={patientAddress}
                            onChange={(event) => setPatientAddress(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="filled-multiline-flexible"
                            label="Ostalo"
                            multiline
                            value={patientOther}
                            onChange={(event) => setPatientOther(event.target.value)}
                            rows={15}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>

          
            <Button onClick={handleSubmit} disableElevation className={classes.confirmButton} variant="contained">
                {patient && "Uredi" || "Dodaj"}
            </Button>
            {patient &&  <Button variant="contained" disableElevation loading={saving} onClick={handleDelete} className={classes.dangerButton}>
                Obriši
            </Button> || null}
            <Button onClick={onClose} variant="contained" disableElevation className={classes.defaultButton}>
                Odustani
            </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PatientEditor;