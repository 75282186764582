import { useState, useEffect } from "react";

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      const windowWidthSet = windowSize && windowSize.width ? true : false;
      const resizeUp = windowWidthSet && windowSize.width < 577 && window.innerWidth > 576;
      const resizeDown = windowWidthSet && windowSize.width > 576 && window.innerWidth < 577;

      // Set window width/height to state
      const trigger = !windowWidthSet || resizeUp || resizeDown || false;
      //console.log("Resize should trigger", trigger, windowSize, window.innerWidth, resizeUp, resizeDown)
      // only trigger change if window size goes to mobile or back
      if(trigger) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize]); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default useWindowSize;
