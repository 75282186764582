import { db, auth } from './firebase.service'; 
import {collection, doc, setDoc, query, getDocs, where } from "firebase/firestore";
import googleCalendarService from './google-calendar.service';

export const calendarService = {
    getCalendars,
    saveCalendarList
};

async function getCalendars() {
    let calendars = [];
    const googleCalendars = await googleCalendarService.list()
    const googleCalendarIds = googleCalendars.data.items.map(gc => gc.id)
    // if(auth?.currentUser?.uid) {
    //     const calendarCollection = await getDocs(query(collection(db, 'calendars'), where('uid', '==', auth.currentUser.uid)));
    //     calendarCollection.forEach((doc) => {
    //         calendars.push({
    //             ...doc.data(),
    //             documentId: doc.id
    //         })
    //     })

    //     console.log("get calendar google api", calendarCollection, googleCalendars)
        

    //     const data = calendars.map(c => {
    //         const calendar = googleCalendars.data.items.find(gc => gc.id == c.id)
    //         return {...calendar, ...c}
    //     })

    //     console.log("Got calendars:", data)
    //     if(data?.length != googleCalendars?.data?.items?.length) {
    //         await saveCalendarList(googleCalendars?.data?.items)
    //     }

    //     return data?.length && data || googleCalendars?.data?.items;
    // }


    if(auth?.currentUser?.uid) {
        const calendarCollection = await getDocs(query(collection(db, 'calendars'), where('uid', '==', auth.currentUser.uid)));
        calendarCollection.forEach((doc) => {
            calendars.push({
                ...doc.data(),
                documentId: doc.id
            })
        })

        console.log("get calendar google api", calendarCollection, googleCalendars)
        
      
        const data = calendars.filter(c => googleCalendarIds.includes(c.id)).map(c => {
            const calendar = googleCalendars.data.items.find(gc => gc.id == c.id)
            return {...calendar, ...c}
        })

        // console.log("Got calendars:", data)
        // if(data?.length != googleCalendars?.data?.items?.length) {
        //     await saveCalendarList(googleCalendars?.data?.items)
        // }

        return data?.length && data || googleCalendars?.data?.items;
    }

    return googleCalendars.data.items
}

async function saveCalendarList(calendars) {
    console.log("Save calendars:", calendars)
    if(calendars) {
        for(let i = 0; i < calendars.length; i++) {
            await setDoc(doc(db, "calendars", `${auth?.currentUser?.uid}_${calendars[i].id}`), 
            {
                ...calendars[i], 
                id: calendars[i].id,
                uid: auth?.currentUser?.uid,
                modifiedAt: (new Date().toISOString()) 
                }, 
                { merge: true }
            );
        }
    }
}

function saveCalendar(calendar) {
    if(calendar) {
       
    
       
       
    }
}