import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AboutSection from "../elements/about";
import LatestNewsSection from "../elements/latest-news-slider";
import FeatureSection3 from "../elements/feature-section3";
import TeamSection from "../elements/team";
import TestimonialSection from "../elements/testimonial";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import ptImg1 from "../../images/shap/wave-orange.png";
import ptImg2 from "../../images/shap/circle-small-blue.png";
import ptImg4 from "../../images/shap/square-dots-orange.png";
import ptImg5 from "../../images/shap/square-blue.png";

class Policy extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Politika privatnosti</h1>
                  {/* <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Početna
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        O Dent.ai
                      </li>
                    </ul> 
                  </nav>*/}
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>

          <section className="section-sp1 about-area">
            <div className="container">
              <div className="row align-items-center heading-bx">
                <div className="col-lg-12 mb-30">
                  <>
                    <p>
                      <em>Last Updated: 2024-01-01</em>
                    </p>
                    <h2>Uvod</h2>
                    <p>
                      Ova Politika privatnosti opisuje kako prikupljamo,
                      koristimo, otkrivamo i štitimo vaše podatke kada
                      pristupate ili koristite našu web aplikaciju.
                    </p>
                    <h2>Podaci koje prikupljamo</h2>
                    <p>
                      <ul>
                        <li>
                          <p>
                            <h5>a. Podaci koje pružate</h5>
                          </p>
                          <p>
                            Kada koristite našu aplikaciju, možemo prikupljati
                            sljedeće vrste podataka:
                            <ul>
                              <li>
                                <strong>Osobni podaci:</strong> Ime, adresa
                                e-pošte i drugi podaci koje pružate kada se
                                registrirate, kontaktirate nas ili koristite
                                naše usluge.
                              </li>
                              <li>
                                <strong>Korisnički generirani sadržaj:</strong>
                                Sadržaj koji kreirate, objavljujete ili dijelite
                                na našoj aplikaciji.
                              </li>
                            </ul>
                          </p>
                        </li>
                        <li>
                          <p>
                            <h5>b. Automatski prikupljeni podaci</h5>
                          </p>
                          <p>
                            Također možemo automatski prikupljati određene
                            podatke kada koristite našu aplikaciju, kao što su:
                            <ul>
                              <li>
                                <strong>Podaci o uređaju:</strong> Informacije o
                                vašem uređaju, kao što su vaša IP adresa, vrsta
                                uređaja i operativni sustav.
                              </li>
                              <li>
                                <strong>Podaci o korištenju:</strong>
                                Informacije o tome kako koristite našu
                                aplikaciju, uključujući stranice koje
                                posjećujete, značajke s kojima komunicirate i
                                radnje koje poduzimate.
                              </li>
                            </ul>
                          </p>
                        </li>
                      </ul>
                    </p>
                    <h2>Kako koristimo vaše podatke</h2>
                    <p>
                      Vaše podatke koristimo u sljedeće svrhe:
                      <ul>
                        <li>
                          Za pružanje, održavanje i poboljšanje naše aplikacije.
                        </li>
                        <li>
                          Za personalizaciju vašeg iskustva na našoj aplikaciji.
                        </li>
                        <li>
                          Za komunikaciju s vama, uključujući slanje ažuriranja,
                          obavijesti i odgovaranje na vaše zahtjeve.
                        </li>
                        <li>
                          Za analizu uzoraka korištenja i optimizaciju naših
                          usluga.
                        </li>
                        <li>
                          Za ispunjavanje zakonskih obveza i zaštitu naših
                          prava.
                        </li>
                      </ul>
                    </p>
                    <h2>Pohrana i zadržavanje podataka</h2>
                    <p>
                      Vaši osobni podaci pohranjeni su sigurno u Firebase
                      Firestore, cloud bazi podataka koju pruža Google.
                      Zadržavamo vaše podatke onoliko dugo koliko je potrebno za
                      ispunjenje svrha navedenih u ovoj politici ili koliko to
                      zahtijeva zakon.
                    </p>
                    <h2>Mehanizmi zaštite podataka</h2>
                    <p>
                      Primjenjujemo razne sigurnosne mjere kako bismo održali
                      sigurnost vaših osobnih podataka. Kada pohranjujemo
                      podatke u Firebase Firestore, koristimo snažne sigurnosne
                      značajke Firebase-a i nadopunjujemo ih vlastitim mjerama:
                      <ul>
                        <li>
                          Enkripcija: Svi podaci pohranjeni u Firebase Firestore
                          su šifrirani tijekom prijenosa i pohrane.
                        </li>
                        <li>
                          Kontrole pristupa: Firebase Firestore koristi pravila
                          kontrole pristupa koja nam omogućuju ograničavanje
                          pristupa podacima na temelju autentifikacije i
                          autorizacije korisnika. Konfiguriramo ova pravila kako
                          bismo osigurali da samo ovlašteno osoblje može
                          pristupiti osjetljivim podacima.
                        </li>
                        <li>
                          Autentifikacija: Koristimo Firebase Authentication
                          kako bismo osigurali da su korisnici ono za što se
                          predstavljaju.
                        </li>
                        <li>
                          Sigurne razvojne prakse: Naš razvoj softvera prati
                          sigurne prakse kodiranja i koristi sigurnosne alate
                          Firebase-a za kontinuirano praćenje i zaštitu naših
                          aplikacija od prijetnji.{" "}
                        </li>
                      </ul>
                    </p>
                    <h2>Dijeljenje vaših podataka</h2>
                    <p>
                      Vaše podatke možemo dijeliti s trećim stranama u sljedećim
                      okolnostima:
                      <ul>
                        <li>Uz vaš pristanak</li>
                        <li>
                          Za ispunjavanje zakonskih zahtjeva ili odgovaranje na
                          zakonite zahtjeve
                        </li>
                        <li>
                          Za zaštitu naših prava i sigurnosti ili prava i
                          sigurnosti drugih.
                        </li>
                      </ul>
                    </p>
                    <h2>
                      1. Izmjena općih uvjeta i pravila uporabe i završne
                      odredbe
                    </h2>
                    <p>
                      Davatelj usluge može mijenjati uvjete i pravila uporabe
                      uključujući cjenike bez prethodne suglasnosti korisnika.
                      Davatelj usluge može mijenjati uvjete i pravila uporabe
                      bez obzira na to ako time mijenja postojeće ugovorne
                      odnose. Ukoliko promjena pravila i uvjeta uporabe nema
                      utjecaja na korisnike tj. pretplatnike, promjene mogu
                      početi važiti s bilo kojim datumom bez prethodne najave
                      (na primjer promjena kontaktnih podataka, itd.).
                    </p>
                    <p>
                      U slučaju promjena koje utječu na pretplatnike tj.
                      korisnike, davatelj usluge će uvažiti razumne interese
                      izražene od strane većinskog udjela korisnika tj.
                      pretplatnika.Za sve pravne odnose vezane za ove uvjete i
                      pravila uporabe isključivo se primjenjuje pravo Republike
                      Hrvatske. Ugovorne strane postižu dogovor da će sve
                      sporove iz međusobnog odnosa rješavati sporazumno,
                      međusobnom komunikacijom. U slučaju nemogućnosti sklapanja
                      sporazuma, za rješavanje svih sporova isključivo je
                      nadležan sud u Bjelovaru.
                    </p>
                    <p>
                      O promjenama koje utječu na pretplatnike i posebno o
                      promjenama cjenika tj. opsega ponude davatelj usluge će
                      obavijestiti pretplatnike barem 30 kalendarskih dana prije
                      početka važenja promjena. O promjenama će davatelj usluge
                      obavijestiti pretplatnike putem svojih internet stranica i
                      obavijesti unutar internetskog programa. Po isteku 30
                      kalendarskih dana pretplatnički odnos nastavlja se u
                      skladu s novim uvjetima i pravilima uporabe.
                    </p>
                    <h2>
                      2. Kontaktirajte nas oko brisanja korisničkih podataka
                      nakon prekida poslovnih potreba
                    </h2>
                    <p>
                      Nakon što korisnik popuni obrazac i pritisne gumb za
                      slanje, zahtjev se šalje vašem timu za podršku koji će ga
                      dalje obraditi u skladu s vašim pravilima i regulativama o
                      privatnosti. Na ovoj stranici također možete uključiti
                      dodatne informacije o postupku brisanja računa, vremenskim
                      okvirima za obradu zahtjeva i kontakt informacije za
                      podršku ako korisnici imaju dodatna pitanja ili probleme.
                      Ovo osigurava transparentnost i olakšava korisnicima da
                      zatraže brisanje svojih računa i podataka..
                    </p>
                    <h2>
                      3. Zahtjev za brisanje svog korisničkog računa i svih
                      podataka vezano uz taj korisnički račun
                    </h2>
                    <p>
                      Zahtjev za brisanje svog korisničkog računa i svih
                      podataka vezano uz taj korisnički račun možete zatražiti
                      slanjem mail na adresu info@miraident.hr.
                    </p>
                    <h2>4. Google usluge</h2>
                    <p>
                      Naša aplikacija može koristiti Google usluge, uključujući,
                      ali ne ograničavajući se na Google Analytics i Google
                      Maps. Kada koristite našu aplikaciju, također podliježete
                      Googleovoj Politici privatnosti koju možete pregledati
                      <a href="https://policies.google.com/privacy">ovdje</a>.
                      Dent-AI-ova upotreba i prijenos bilo kojih drugih
                      aplikacija primljenih od Google API-ja pridržavat će se
                      <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
                        Google API Services User Data Policy
                      </a>
                      , uključujući zahtjeve za ograničenu upotrebu.
                    </p>
                    <h2>5. AI/ML</h2>
                    <p>
                      VAŽNO!{" "}
                      <strong>
                        NE KORISTIMO vaše podatke niti podatke prikupljene putem
                        Google Workspace API-ja tj. usluga
                      </strong>{" "}
                      kako bi razvili, trenirali ili poboljšali generalizirane
                      AI i/ili ML modele.
                    </p>
                  </>
                </div>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
            <img className="pt-img2 animate2" src={ptImg2} alt="" />
            <img className="pt-img3 animate-rotate" src={ptImg5} alt="" />
            <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
            <img className="pt-img5 animate2" src={ptImg5} alt="" />
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Policy;
