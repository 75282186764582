import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import FileList from './FileList';
import { patientService } from '../../services/patient.service';
import './UploadPicker.css';
import makeStyles from '@mui/styles/makeStyles';

// const useStyles = makeStyles((theme) => ({
//     defaultButton: {
//         margin: theme.spacing(1),
//         backgroundColor: "#e0e0e0",
//         color: "rgba(0, 0, 0, 0.87)",
//         fontWeight: '600',
//         fontSize: '1.4rem',
//         padding: '0.5rem 2rem',
//         '&:hover': {
//             backgroundColor: "#eeeeee",
//             //boxShadow: "0 10px 40px 0 rgba(189, 189, 189, 0.8)",
//         },
//         //boxShadow: "0 10px 40px 0 rgba(189, 189, 189, 0.5)",
//     },
//     confirmButton: {
//         margin: theme.spacing(1),
//         backgroundColor: "#8bc34a",
//         color: "rgba(0, 0, 0, 0.87)",
//         fontWeight: '600',
//         fontSize: '1.4rem',
//         padding: '0.5rem 2rem',
//         '&:hover': {
//             backgroundColor: "#a2cf6e",
//             //boxShadow: "0 10px 40px 0 rgba(97, 136, 51, 0.8)",
//         },
//        // boxShadow: "0 10px 40px 0 rgba(97, 136, 51, 0.5)",
//     },
//     dangerButton: {
//         margin: theme.spacing(1),
//         backgroundColor: "#e53935",
//         color: "#fff",
//         fontWeight: '600',
//         fontSize: '1.4rem',
//         padding: '0.5rem 2rem',
//         '&:hover': {
//             backgroundColor: "#f44336",
//             //boxShadow: "0 10px 40px 0 rgba(211, 47, 47, 0.8)",
//         },
//         //boxShadow: "0 10px 40px 0 rgba(211, 47, 47, 0.5)",
//     },
// }));

const UploadEditor = ({ onClose, patient, onHandleUpload, ...props}) => {
    //const classes = useStyles();
    const [selectedFolder, setSelectedFolder] = useState(null)
    console.log("Got parent folder", patient)

    React.useEffect(() => {
        setSelectedFolder()
    }, [])

    const handleClose = () => {
        if(!selectedFolder) {
            onClose()
        }
        setSelectedFolder(null)
    }

    const handleUploadFiles = async () => {
        let folder = null
        switch(selectedFolder) {
            case "Images":
                folder = await patientService.getImagesFolder(patient?.id)
                break;
            case "Video":
                folder = await patientService.getVideoFolder(patient?.id)
                break;
            case "RTG":
                folder = await patientService.getRTGFolder(patient?.id)
                break;
            case "Offers":
                folder = await patientService.getOffersFolder(patient?.id)
                break;
        }

        handleClose()
        onHandleUpload(folder[0])
    }

    const translate = (input) => {
        if(input == "Images") {
            return "Slike"
        }

        if(input == "Offers") {
            return "Ponude"
        }

        return input
    }

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth="lg" fullWidth="lg">
            <DialogTitle id="form-dialog-title">
                <span style={{color: '#7A3D79', cursor: 'pointer'}} onClick={() => setSelectedFolder(null)}>{patient?.name}</span>
                {selectedFolder && <>{" > "} {translate(selectedFolder)}</> || null}

            </DialogTitle>
            <DialogContent>
            <DialogContentText>
                {!selectedFolder && "Odaberite mapu za pregled datoteka" || "Pregled datoteka"}
            </DialogContentText>
                {!selectedFolder && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Button className="patient-folder" variant="contained" disableElevation onClick={()=> setSelectedFolder("Images")}>Slike</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button className="patient-folder" variant="contained" disableElevation onClick={()=> setSelectedFolder("Video")}>Video</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button className="patient-folder" variant="contained" disableElevation onClick={()=> setSelectedFolder("Offers")}>Ponude</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button className="patient-folder" variant="contained" disableElevation onClick={()=> setSelectedFolder("RTG")}>RTG</Button>
                        </Grid>
                    
                    </Grid>
                ) || null}
                {selectedFolder && (
                    <FileList selectedFolder={selectedFolder} patient={patient} />
                ) || null}
            </DialogContent>
            <DialogActions>
       
            {selectedFolder && (
                <Button onClick={handleUploadFiles} color="primary" className="confirmButton">
                    Učitaj datoteke
                </Button>
            ) || null}
                <Button onClick={handleClose} color="primary"  className="defaultButton">
                    {selectedFolder && "Povratak" || "Odustani"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UploadEditor;