import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AboutSection from "../elements/about";
import LatestNewsSection from "../elements/latest-news-slider";
import FeatureSection3 from "../elements/feature-section3";
import TeamSection from "../elements/team";
import TestimonialSection from "../elements/testimonial";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import ptImg1 from "../../images/shap/wave-orange.png";
import ptImg2 from "../../images/shap/circle-small-blue.png";
import ptImg4 from "../../images/shap/square-dots-orange.png";
import ptImg5 from "../../images/shap/square-blue.png";

const AboutUs = () => {
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>O dent.ai Smart Assistant</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>{" "}
                        Početna
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      O Dent.ai
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
            <img className="pt-img2 animate2" src={circleDots} alt="" />
            <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
          </div>
        </div>

        <section className="section-sp1 about-area">
          <div className="container">
            <div className="row align-items-center heading-bx">
              <div className="col-lg-12 mb-30">
                <h2 className="title">Pametni asistent Dent.ai</h2>
                <p>
                  DentAI, Dental Artificial Intelligence, softver je zamišljen
                  kao pametni asistent koji samostalno prikuplja, sistematizira
                  i organizira medijske sadržaje i podatke o pacijentu (RTG
                  snimke, fotografije, video zapisi i sl.) i nemedijske
                  dokumente, te ih objedinjuje na jednome mjestu.{" "}
                </p>

                <p>
                  Vaš pametni asistent Dent.ai pristiglim medijskim datotekama
                  upravlja samostalno kroz nekoliko koraka:
                  <ul>
                    <li>
                      Samostalno prepoznaje elektroničku poštu kao sadržaj od
                      interesa odmah nakon što pošta pristigne, neovisno o tome
                      da li je pošta otvorena od strane djelatnika ordinacije{" "}
                    </li>
                    <li>
                      Samostalno učitava medijski sadržaj, te ga klasificira kao
                      RTG snimku, fotografiju, video zapis i sl.{" "}
                    </li>
                    <li>
                      Samostalno prepoznaje oznaku pacijenta na kojeg se
                      medijska datoteka odnosi{" "}
                    </li>
                    <li>
                      Ako je pacijentov folder otvoren pristigli sadržaj
                      dodjeljuje pacijentu, u odgovarajuću klasu (RTG snimke u
                      klasu RTG snimke itd.){" "}
                    </li>
                    <li>
                      Ako pacijentov folder nije otvoren – kreira novog
                      pacijenta, otvara klase, dodjeljuje pristigli sadržaj u
                      odgovarajuću klasu{" "}
                    </li>
                  </ul>
                </p>

                <p>
                  Proces je isti i kada ordinacija samostalno kreira medijsku
                  datoteku, odnosno kada ima vlastiti RTG uređaj, a RTG snimke
                  su u digitalnom formatu i automatski se snimaju na računalo.
                  DentAI tu medijsku datoteku prepoznaje kao sadržaj od interesa
                  i proces se nastavlja kao u prethodnom slučaju.
                </p>
                <p>
                  Kod drugog medijskog sadržaja nastalog u ordinaciji koji se
                  učitava digitalno na računalo ordinacije, sadržaj se prenosi
                  na ime kreiranog pacijenta. DentAI prepoznaje medijski
                  sadržaj, klasificira, pohranjuje kod postojećeg pacijenta, ili
                  kreira novog pacijenta, otvara sve klase te medijski sadržaj
                  klasificira i pohranjuje.
                </p>
                <p>
                  Zdravstvene ustanove često imaju više ambulanta, te imaju
                  jedan RTG uređaja. Problem takvih organizacija je efikasna
                  distribucija RTG snimaka u pojedinu ambulantu. Nakon izrade
                  RTG snimke ista se mora dostaviti ili putem maila pojedinoj
                  ambulanti, ili putem drugog medija (usb, cd, mrežnim
                  dijeljenim mapama i sl.). Nakon primitka RTG snimke ambulanta
                  istu mora učitati, otvoriti, dostavljene medijske datoteke
                  preuzeti na računalo, datoteke kategorizirati, te ih pohraniti
                  u dosje pacijenta, koji prethodno mora biti kreiran. Navedeno
                  predstavlja nepotreban i dvostruki obim posla za istu
                  ustanovu.
                </p>
                <p>
                  Svaka RTG snimka ima oznaku pacijenta, najčešće se radi o
                  imenu i prezimenu. Izradom RTG snimke DentAI taj medijski
                  sadržaj prepoznaje kao sadržaj od interesa, samostalno
                  prepoznaje oznaku pacijenta i kao u prethodnim slučajevima
                  otvara novog pacijenta, klasificira medijski sadržaj, te ga
                  pohranjuje ili klasificira i pohranjuje medijski sadržaj u
                  postojećeg pacijenta. Medijski sadržaj nakon toga odmah je
                  dostupan u svim ambulantama. Doktori sadržaju pristupaju
                  jednim klikom na ime pacijenta.
                </p>
                <p>
                  Za ne-medijske sadržaje različitih formata poput ponuda,
                  radnih naloga, računa i sl. DentAI primjenjuje iste procese
                  kao i kod medijskih sadržaja. Dokument je potrebno prenijeti
                  na ime postojećeg ili ikonu novog pacijenta i pokreću se
                  procesi klasificiranja i pohranjivanja.{" "}
                </p>
                <p>
                  Dokumenti ovog tipa mogu se dodatno uređivati unutar pametnog
                  asistenta, te dostaviti pacijentu elektroničkom poštom jednim
                  klikom.
                </p>
              </div>
            </div>
          </div>
          <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
          <img className="pt-img2 animate2" src={ptImg2} alt="" />
          <img className="pt-img3 animate-rotate" src={ptImg5} alt="" />
          <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
          <img className="pt-img5 animate2" src={ptImg5} alt="" />
        </section>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
