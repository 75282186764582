import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCTL7KN8-DSfkDp4Z9NfQ_SrSIE57Tos1A",
  authDomain: "dent-ai.firebaseapp.com",
  projectId: "dent-ai",
  storageBucket: "dent-ai.appspot.com",
  messagingSenderId: "38500742429",
  appId: "1:38500742429:web:99316f1a46885266ee5df4",
  measurementId: "G-VR7T2KEYR1",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "consent" });
provider.addScope("https://www.googleapis.com/auth/drive.file");
provider.addScope("https://www.googleapis.com/auth/calendar");

export const signInWithGoogle = () => signInWithPopup(auth, provider);
export const signOutUser = () => signOut(auth);

export { app, auth, db, functions };
