import React, { useContext } from "react";
import Button from '@mui/material/Button';
import { AuthContext } from "../../context/AuthContext";
import "./Authorize.css"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';

const Authorize = ({googleLogin}) => {
    const {invalidSession, setInvalidSession} = useContext(AuthContext)

    // const handleClose = () => {
    //     setInvalidSession(false)
    // }

    // return (
    //     <Dialog
    //         open={invalidSession}
    //         onClose={handleClose}
    //     >
    //         <DialogTitle>Dozvola pristupa</DialogTitle>
    //         <DialogContent>
    //         <DialogContentText>
    //             <span>{invalidSession ? "Istekla vam je sesija, morate ponovno dozvoliti pristup Google uslugama" : "Dozvolite pristup Google uslugama"}</span>
    //         </DialogContentText>
    //         </DialogContent>
    //         <DialogActions>
    //         <Button onClick={handleClose}>
    //             Odbij
    //         </Button>
    //         <Button onClick={() => googleLogin()} color="primary" autoFocus>
    //             Dozvoli
    //         </Button>
    //         </DialogActions>
    //     </Dialog>
    // )
    return (<div className="authorize-backdrop">


       <Paper style={{padding: '2rem 3rem', display: 'flex', alignItems: 'stretch', flexDirection: 'column', justifyContent: 'center'}}>
        <h3>

        {invalidSession && 
            "Istekla vam je sesija, morate ponovno dozvoliti pristup Google uslugama"
        || "Dozvolite pristup Google uslugama"}
        </h3>
       <Button variant="contained" style={{background: '#7A3D79', marginTop: '0.5rem', boxShadow: '0 10px 40px 0 rgb(122 61 121 / 50%)'}} color="primary" onClick={() => googleLogin()}>
            Dozvoli pristup
        </Button>
       </Paper>
 
    </div>)
}

export default Authorize;