
import { db, auth } from './firebase.service'; 
import {collection, doc, getDoc, setDoc, query, getDocs, where } from "firebase/firestore";

export const noteService = {
    saveNote,
    getNotes
};

async function getNotes(patientId) {
    let notes = [];
    if(auth?.currentUser?.uid) {
        const notesCollection = await getDocs(query(collection(db, 'notes'), where('uid', '==', auth.currentUser.uid), where('patientId', '==', patientId)));
        notesCollection.forEach((doc) => {
            notes.push({
                ...doc.data(),
                id: doc.id
            })
        })
    }

    return notes
}

async function saveNote(patientId, note) {
    if(auth?.currentUser?.uid && patientId && note) {
        const notesRef = note.id && doc(db, "notes", note.id) || doc(collection(db, "notes"));

        await setDoc(notesRef, {
            ...note,
            uid: auth?.currentUser?.uid,
            patientId: patientId,
            date: (new Date().toISOString())
        },
        { merge: true });
    }
}