import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import { patientService } from '../../services/patient.service';
import googleDriveService from '../../services/google-drive.service';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Button } from '@mui/material';
const FileList = ({selectedFolder, patient,  ...props}) => {
    const [files, setFiles] = useState()
    React.useEffect(() => {
        const fetchData = async () => {
            if(patient) {
                let data = []
                switch(selectedFolder) {
                    case "Images":
                        data = await patientService.getImageFiles(patient?.id)
                        break;
                    case "Video":
                        data = await patientService.getVideoFiles(patient?.id)
                        break;
                    case "Offers":
                        data = await patientService.getOffers(patient?.id)
                        break;
                    case "RTG":
                        data = await patientService.getRTGFiles(patient?.id)
                        break;
                }
                 
                setFiles(data)
            }
            
        }
        fetchData()
    }, [selectedFolder])

    async function displayProtectedImage(imageElementId, imageId) {
        // Fetch the image.
        // Update the source of the image.
        const objectUrl = await googleDriveService.getImage(imageId)
        const imageElement = document.getElementById(imageElementId);
        if(objectUrl && imageElement) {
            imageElement.src = URL.createObjectURL(objectUrl.data)
            imageElement.onload = () => URL.revokeObjectURL(objectUrl);
        }
    }

    const downloadFile = async (file) => {
        const element = document.createElement("a");
        const objectUrl = await googleDriveService.getImage(file.id)
        if(element && objectUrl) {
            element.href = URL.createObjectURL(objectUrl.data);
            element.download = file.name;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }

    }

    // thumbnailLink, hasThumbnail, fileExtension, mimeType, name, size, webContentLink
    return (<>
        {!files &&  <CircularProgress /> || null}
        {files && (
            <Grid container spacing={3}>
                {!files?.length && <div style={{width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center', backgroundColor: '#F3F7FB', borderRadius: '15px', padding: '1rem', marginTop: '1rem', marginBottom: '1rem'}}>Nema učitanih datoteka</div> || null}
                {files?.map(file => {
                    console.log("Got file", file)
                    if(file.mimeType.includes("image")) {
                        displayProtectedImage(`img-${file.id}`, file.id)
                    }
                    
                    return (
                        <Grid item xs={12}>
                            <div key={`file-${file.id}`} style={{width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center', backgroundColor: '#F3F7FB', borderRadius: '15px', padding: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem', marginBottom: '1rem'}}>
                                {file.hasThumbnail && file.mimeType.includes("image") && (
                                    <div key={`file-${file.id}`} style={{height: '60px', width: '60px', borderRadius: '16px', overflow: 'hidden', background: '#000'}}>
                                        <img key={`img-${file.id}`} id={`img-${file.id}`} style={{ width: '60px', height: '60px', objectFit: 'cover'}} />
                                    </div>
                                ) || null}
                                <div style={{cursor: 'pointer'}}>
                                    {file.name} ({file.mimeType}, {Math.round(file.size / 1024, 2)} KB)
                                </div>
                                <div style={{marginLeft: 'auto', style: 'flex', gap: '1rem'}}>
                                    <Button title="Preuzmi datoteku"><CloudDownloadIcon fontSize='large' onClick={() => downloadFile(file)} /></Button>
                                </div>
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
        )}

    </>)
}

export default FileList;