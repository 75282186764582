
import { db, auth } from './firebase.service'; 
import {collection, doc, getDoc, setDoc, query, getDocs, where, deleteDoc } from "firebase/firestore";
import googleDriveService from './google-drive.service';
import googleMailService from './google-mail.service';
import AppConfig from '../config/AppConfig';
import axios from 'axios';
import { tenantService } from './tenant.service';

export const patientService = {
    getImages,
    getAllPatients,
    getOffers,
    getPatientInfo,
    savePatientInfo,
    addPatient,
    syncFolders,
    deletePatient,
    importPatient,
    getFolderId,
    getImage,
    getImageFiles,
    getVideoFiles,
    getRTGFiles,
    getRTGFolder,
    getImagesFolder,
    getVideoFolder,
    getOffersFolder
};

async function getAllPatients() {
    /*
    console.log("Get access token:", window.gapi.client.getToken())
    const parent = await window.gapi.client.drive.files.list({
        //'pageSize': 10,
        'q': 'mimeType=\'application/vnd.google-apps.folder\' and name = \'patients\' and trashed = false',
        fields: 'files(id)',
    });

    console.log("Get parent:", parent)
    const response = await window.gapi.client.drive.files.list({
        //'pageSize': 10,
        'q': `mimeType=\'application/vnd.google-apps.folder\' and ${parent.result.files[0].id}' in parents and trashed = false`,
        fields: 'files(id)',
    });
    */
    const parent = await googleDriveService.list("q=name = 'patients' and trashed = false and mimeType='application/vnd.google-apps.folder'&fields=files(id)")
    const response = await googleDriveService.list(`q='${parent.data.files[0].id}' in parents and trashed = false and mimeType='application/vnd.google-apps.folder'&fields=files(id,name)`)
    return response.data.files
}

async function getImages(patientId) {
    if(patientId) {
        const mediaFolder = await googleDriveService.list(`q=name = 'Media' and '${patientId}' in parents and trashed = false&fields=files(id)`);
        const folders = await googleDriveService.list(`q='${mediaFolder.data.files[0].id}' in parents and trashed = false&fields=files(id,name,mimeType)`);
        const files = await Promise.all(folders.data.files.map(f => googleDriveService.list(`q='${f.id}' in parents and trashed = false&fields=files(*)`)))
        
        return [...folders.data.files, ...files.flatMap(f => f.data.files)] || []
    }

    return []
}

async function getOffers(patientId) {
    if(patientId) {
        const offersFolder = await googleDriveService.list(`q=name = 'Offers' and '${patientId}' in parents and trashed = false&fields=files(id)`);
        console.log("Got offer files:", patientId, offersFolder.data.files[0])
        
        const files = await googleDriveService.list(`q='${offersFolder.data.files[0].id}' in parents and trashed = false&fields=files(*)`)
       
        return files.data.files || []
    }

    return []
}

async function getPatientInfo(patientId) {
    if(patientId && auth?.currentUser?.uid) {
        const userPatientsDoc = await getDoc(doc(db, 'patients', `${auth?.currentUser?.uid}_${patientId}`));
        if(userPatientsDoc.exists()) {
            const patient = {
                id: patientId, 
                uid: auth?.currentUser?.uid, 
                ...userPatientsDoc.data()
            }

            return patient
        }
    }

    return null
}

async function savePatientInfo(patient, info) {
    if(patient && auth?.currentUser?.uid) {
        await setDoc(doc(db, "patients", `${auth?.currentUser?.uid}_${patient.id}`), {...patient, ...info}, { merge: true });
    }
}

async function addPatient(patient) {
    if(patient && auth?.currentUser?.uid) {

        try {
            let parent = await googleDriveService.list("q=name = 'patients' and trashed = false and mimeType='application/vnd.google-apps.folder'&fields=files(id)");
            if(!parent?.data?.files?.length) {
                const patientsFolder = await googleDriveService.create({
                    'name': 'patients',
                    'mimeType': 'application/vnd.google-apps.folder',
                });
                parent = patientsFolder;
            }

            console.log("Parent folder to store data in:", parent.data.files[0].id)
            const patientFileMetadata = {
                'name': patient.name,
                'mimeType': 'application/vnd.google-apps.folder',
                'parents': [
                    parent.data.files[0].id
                ]
            };
         
            const patientFolder = await googleDriveService.create(patientFileMetadata);
            const patientId = patientFolder.data.id;
         
            await googleDriveService.create({
                    'name': 'Offers',
                    'mimeType': 'application/vnd.google-apps.folder',
                    'parents': [
                        patientId
                    ]
                });

            const mediaFolder = await googleDriveService.create({
                    'name': 'Media',
                    'mimeType': 'application/vnd.google-apps.folder',
                    'parents': [
                        patientId
                    ]
                });

            await googleDriveService.create({
                    'name': 'Video',
                    'mimeType': 'application/vnd.google-apps.folder',
                    'parents': [
                        mediaFolder.data.id
                    ]
                });

            await googleDriveService.create({
                    'name': 'Images',
                    'mimeType': 'application/vnd.google-apps.folder',
                    'parents': [
                        mediaFolder.data.id
                    ]
                });

            await googleDriveService.create({
                    'name': 'RTG',
                    'mimeType': 'application/vnd.google-apps.folder',
                    'parents': [
                        mediaFolder.data.id
                    ]
                });

            await setDoc(doc(db, "patients", `${auth?.currentUser?.uid}_${patientId}`), 
                {
                    ...patient, 
                    patientId: patientId, 
                    id: patientId,
                    uid: auth?.currentUser?.uid,
                    created: (new Date().toISOString()) 
                }, 
                { merge: true }
            );
            
          } catch (err) {
            console.error("Got gdrive error:", err.message)
            return;
          }

       
    }
}



async function syncFolders(alreadySyncedAttachments) {
    if(auth?.currentUser?.uid) {
        try {
            const res = await axios.post(`${AppConfig.apiUrl}/sync`, {alreadySyncedAttachments})
            if(res?.data) {
                return res.data
                //await tenantService.setSyncedAttachments(syncedAttachments)
            }
        }
        catch(error) {
            console.log("Attachment upload error", error)
        }

        // let syncedAttachments = []
        // try {
        //     const tempFolder = await googleDriveService.list("q=name = 'ATemp' and trashed = false and mimeType='application/vnd.google-apps.folder'&fields=files(id)");

            
  
        //     const files = await googleDriveService.list(`q=trashed = false and '${tempFolder.data.files[0].id}' in parents&fields=files(id, name)`);
        //     console.log("Parent folder to store data in:", tempFolder.data.files[0].id, files)

        //     if(files.data.files.length) {
        //         const alreadySyncedAttachments = await tenantService.getSyncedAttachments()
        //         const notSynced = files.data.files.filter(x => !alreadySyncedAttachments?.includes(x.name));

        //         console.log("Not synced stuff in folder", notSynced)
        //         for(let i = 0; i < notSynced.length; i++) {
        //             const file = notSynced[i]
        //             let parts = file.name.replace(/\.[^/.]+$/, "").toUpperCase().trim().split('_')
        //             const patientName = `${parts[0]}${parts.length > 1 && `_${parts[1]}` || ''}`
        //             //const patientName = file.name.replace(/\.[^/.]+$/, "").toUpperCase().trim().split('_LIGHT').shift()
        //             const patientFolder = await googleDriveService.list(`q=name = '${patientName}' and trashed = false and mimeType='application/vnd.google-apps.folder'&fields=files(id)`);

        //             if(!patientFolder?.data?.files?.length) {
        //                 await addPatient({name: patientName})
        //             }

        //             const mediaParent = await googleDriveService.list(`q='${patientFolder.data.files[0].id}' in parents and name = 'Media' and trashed = false and mimeType='application/vnd.google-apps.folder'&fields=files(id)`);
        //             const mediaFolders = await googleDriveService.list(`q=trashed = false and '${mediaParent.data.files[0].id}' in parents&fields=files(id, name)`);

        //             const rtgFolder = mediaFolders.data.files.find(f => f.name == 'RTG')
        //             await googleDriveService.update(file.id, `removeParents=${tempFolder.data.files[0].id}&addParents=${rtgFolder.id}`)
        //             await tenantService.setSyncedAttachments([file.name])
        //             //console.log("Got file:", file, patientName, patientFolder, mediaFolders)
        //         }
        //     }
        // } catch (err) {
        //     console.error("Got sync gdrive error:", err?.message)
        //     return;
        // }

        // return syncedAttachments
    }

    return {syncedAttachments: [], createdPatients: []}
}

async function deletePatient(patientId) {
    if(patientId && auth?.currentUser?.uid) {   
        await googleDriveService.deleteFile(patientId)
        try {
            await deleteDoc(doc(db, "patients", `${auth?.currentUser?.uid}_${patientId}`));
        }
        catch(error) {
            console.error("Firebase delete patient error", error)
        }
       
       
    }

    return null
}


async function importPatient(patient) {
    if(patient && auth?.currentUser?.uid) {

        try {
            // const parent = await googleDriveService.list("q=name = 'patients' and trashed = false and mimeType='application/vnd.google-apps.folder'&fields=files(id)");
      
            // console.log("Parent folder to store data in:", parent.data.files[0].id)
            // const patientFileMetadata = {
            //     'name': patient.name,
            //     'mimeType': 'application/vnd.google-apps.folder',
            //     'parents': [
            //         parent.data.files[0].id
            //     ]
            // };
         
            // const patientFolder = await googleDriveService.create(patientFileMetadata);
            // const patientId = patientFolder.data.id;
         
            // await googleDriveService.create({
            //         'name': 'Offers',
            //         'mimeType': 'application/vnd.google-apps.folder',
            //         'parents': [
            //             patientId
            //         ]
            //     });

            // const mediaFolder = await googleDriveService.create({
            //         'name': 'Media',
            //         'mimeType': 'application/vnd.google-apps.folder',
            //         'parents': [
            //             patientId
            //         ]
            //     });

            // await googleDriveService.create({
            //         'name': 'Video',
            //         'mimeType': 'application/vnd.google-apps.folder',
            //         'parents': [
            //             mediaFolder.data.id
            //         ]
            //     });

            // await googleDriveService.create({
            //         'name': 'Images',
            //         'mimeType': 'application/vnd.google-apps.folder',
            //         'parents': [
            //             mediaFolder.data.id
            //         ]
            //     });

            // await googleDriveService.create({
            //         'name': 'RTG',
            //         'mimeType': 'application/vnd.google-apps.folder',
            //         'parents': [
            //             mediaFolder.data.id
            //         ]
            //     });

            await setDoc(doc(db, "patients", `${auth?.currentUser?.uid}_${patient.id}`), 
                {
                    ...patient, 
                    // patientId: patientId, 
                    // id: patientId,
                    uid: auth?.currentUser?.uid,
                    //created: (new Date().toISOString()) 
                }, 
                { merge: true }
            );
            
          } catch (err) {
            console.error("Got gdrive error:", err.message)
            return;
          }

       
    }

  
}

async function getFolderId(patientName) {
    if(patientName) {
        let parts = patientName.replace(/\.[^/.]+$/, "").toUpperCase().trim().split('_')
        const patientNameParsed = `${parts[0]}${parts.length > 1 && `_${parts[1]}` || ''}`

        const patientFolder = await googleDriveService.list(`q=name = '${patientNameParsed}' and trashed = false and mimeType='application/vnd.google-apps.folder'&fields=files(id)`);

        console.log("Got patient folder", patientFolder?.data)
        return patientFolder?.data?.files[0]?.id;
    }
}

async function getImage(imageId) {
    if(auth?.currentUser?.uid) {
        try {
            const res = await axios.get(`${AppConfig.apiUrl}/sync/image?id=${imageId}`)
            console.log("Got image reponse from server", res.data)
            return res
        }
        catch(error) {
            console.log("Attachment upload error", error)
        }
    }
}

async function getImageFiles(patientId) {
    if(patientId) {
        const mediaFolder = await googleDriveService.list(`q=name = 'Media' and '${patientId}' in parents and trashed = false&fields=files(id)`);
        const folders = await googleDriveService.list(`q='${mediaFolder.data.files[0].id}' in parents and trashed = false&fields=files(id,name,mimeType)`);
        const files = await Promise.all(folders.data.files.filter(f => f.name == "Images").map(f => googleDriveService.list(`q='${f.id}' in parents and trashed = false&fields=files(*)`)))
        
        console.log("Got folders for media", folders, files)
        return [...files.flatMap(f => f.data.files)] || []
    }

    return []
}

async function getVideoFiles(patientId) {
    if(patientId) {
        const mediaFolder = await googleDriveService.list(`q=name = 'Media' and '${patientId}' in parents and trashed = false&fields=files(id)`);
        const folders = await googleDriveService.list(`q='${mediaFolder.data.files[0].id}' in parents and trashed = false&fields=files(id,name,mimeType)`);
        const files = await Promise.all(folders.data.files.filter(f => f.name == "Video").map(f => googleDriveService.list(`q='${f.id}' in parents and trashed = false&fields=files(*)`)))
        
        console.log("Got folders for media", folders, files)
        return [...files.flatMap(f => f.data.files)] || []
    }

    return []
}

async function getRTGFiles(patientId) {
    if(patientId) {
        const mediaFolder = await googleDriveService.list(`q=name = 'Media' and '${patientId}' in parents and trashed = false&fields=files(id)`);
        const folders = await googleDriveService.list(`q='${mediaFolder.data.files[0].id}' in parents and trashed = false&fields=files(id,name,mimeType)`);
        const files = await Promise.all(folders.data.files.filter(f => f.name == "RTG").map(f => googleDriveService.list(`q='${f.id}' in parents and trashed = false&fields=files(*)`)))
        
        console.log("Got folders for media", folders, files)
        return [...files.flatMap(f => f.data.files)] || []
    }

    return []
}

async function getRTGFolder(patientId) {
    if(patientId) {
        const mediaFolder = await googleDriveService.list(`q=name = 'Media' and '${patientId}' in parents and trashed = false&fields=files(id)`);
        const folders = await googleDriveService.list(`q='${mediaFolder.data.files[0].id}' in parents and trashed = false&fields=files(id,name,mimeType)`);
       //const files = await Promise.all(folders.data.files.filter(f => f.name == "RTG").map(f => googleDriveService.list(`q='${f.id}' in parents and trashed = false&fields=files(*)`)))
        
        console.log("Got folders for media", ...folders.data.files.filter(f => f.name == "RTG"))
        return [...folders.data.files.filter(f => f.name == "RTG")] || []
    }

    return []
}

async function getImagesFolder(patientId) {
    if(patientId) {
        const mediaFolder = await googleDriveService.list(`q=name = 'Media' and '${patientId}' in parents and trashed = false&fields=files(id)`);
        const folders = await googleDriveService.list(`q='${mediaFolder.data.files[0].id}' in parents and trashed = false&fields=files(id,name,mimeType)`);
       //const files = await Promise.all(folders.data.files.filter(f => f.name == "RTG").map(f => googleDriveService.list(`q='${f.id}' in parents and trashed = false&fields=files(*)`)))
        
        console.log("Got folders for media", folders)
        return [...folders.data.files.filter(f => f.name == "Images")] || []
    }

    return []
}

async function getVideoFolder(patientId) {
    if(patientId) {
        const mediaFolder = await googleDriveService.list(`q=name = 'Media' and '${patientId}' in parents and trashed = false&fields=files(id)`);
        const folders = await googleDriveService.list(`q='${mediaFolder.data.files[0].id}' in parents and trashed = false&fields=files(id,name,mimeType)`);
       //const files = await Promise.all(folders.data.files.filter(f => f.name == "RTG").map(f => googleDriveService.list(`q='${f.id}' in parents and trashed = false&fields=files(*)`)))
        
        console.log("Got folders for media", folders)
        return [...folders.data.files.filter(f => f.name == "Video")] || []
    }

    return []
}

async function getOffersFolder(patientId) {
    if(patientId) {
        const offersFolder = await googleDriveService.list(`q=name = 'Offers' and '${patientId}' in parents and trashed = false&fields=files(id)`);
   
  
        return offersFolder.data.files || []
    }

    return []
}