import React,  { useRef }  from 'react';

import Sidebar from '../Sidebar';
import Profile from '../Profile';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NavButton from '../NavButton';
import {NavLink} from 'react-router-dom'
import { useLocation} from "react-router-dom";
import Notes from "../Notes";
import AppLogo from '../AppLogo';
import "../../App.css"


const ApplicationSidebar = ({notes, onSelectNote, ...props}) => {

    const mainRef = useRef(null);
    const location = useLocation();

    React.useEffect(() => {
        mainRef && mainRef.current.focus();
    }, [])

    return (
        <Sidebar position="left">
            <AppLogo />
            <Profile />
            <div className="site-nav">
                <NavLink to="/patients">
                    <NavButton ref={mainRef} variant="contained" size="large" startIcon={<HomeWorkIcon />} className={location.pathname == "/patients" && "selected" || "button"} color="primary">
                        Početna
                    </NavButton>
                </NavLink>
                <NavLink to={{ 
                    pathname: "/patients/dashboard", 
                    state: {
                        patient: props.patient
                    }
                }}>
                    <NavButton ref={mainRef} variant="contained" size="large" startIcon={<DashboardIcon />} className={location.pathname == "/dashboard" && "selected" || "button"} color="primary">
                        Pregled
                    </NavButton>
                </NavLink>
                <NavLink to={{ 
                    pathname: "/patients/calendar", 
                    state: {
                        patient: props.patient
                    }
                }}>
                    <NavButton variant="contained" size="large" startIcon={<DateRangeIcon />} className={location.pathname == "/calendar" && "selected" || "button"} color="primary">
                        Kalendar
                    </NavButton>
                </NavLink>
                <NavButton variant="contained" selected size="large" startIcon={<AssessmentIcon />} onClick={props.onNotesClick} className="button" color="primary">
                    Bilješka
                </NavButton>
            </div>
            {notes && notes.length && <Notes selectNote={onSelectNote} notes={notes.sort((a,b) => new Date(b.date) - new Date(a.date))} /> || null}
        </Sidebar>
    )
}

export default ApplicationSidebar;