import { handleResponse } from '../helpers';
import AppConfig from '../config/AppConfig';
import axios from 'axios';

export const emailService = {
    openEmail,
    sendOfferEmail,
    sendAppointmentEmail,
    sendCancellationEmail,
    sendServiceActivationRequest
};

function openEmail() {
    const requestOptions = { 
        method: 'GET', 
        headers: { 'Content-Type': 'application/json'},
    };

    return fetch(`${AppConfig.apiUrl}/email`, requestOptions).then(handleResponse).then((data) => {
        return data;
    });
}

function sendOfferEmail(patient, offer, userEmail) {
    // const requestOptions = {  
    //     method: 'POST', 
    //     headers: { 'Content-Type': 'application/json'},
    //     body: JSON.stringify() 
    // };

    return axios.post(`${AppConfig.apiUrl}/email/offer`, {patient, offer, fromEmail: userEmail});
}


function sendAppointmentEmail(patient, appointment, from) {
    const requestOptions = { 
        method: 'POST', 
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({patient, appointment, from }) 
    };

    return fetch(`${AppConfig.apiUrl}/email/appointment`, requestOptions).then(handleResponse).then((data) => {
        return data;
    })
}

function sendCancellationEmail(patient, appointment, from) {
    const requestOptions = { 
        method: 'POST', 
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({patient, appointment, from }) 
    };

    return fetch(`${AppConfig.apiUrl}/email/cancel`, requestOptions).then(handleResponse).then((data) => {
        return data;
    })
}

function sendServiceActivationRequest(user) {
    const requestOptions = { 
        method: 'POST', 
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ user }) 
    };

    return fetch(`${AppConfig.apiUrl}/email/activate`, requestOptions).then(handleResponse).then((data) => {
        return data;
    })
}