import React, { useContext, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { AuthContext } from '../../context/AuthContext';
import { tenantService } from '../../services/tenant.service';

const useStyles = makeStyles((theme) => ({
    defaultButton: {
        margin: theme.spacing(1),
        backgroundColor: "#e0e0e0",
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: '600',
        fontSize: '1.4rem',
        padding: '0.5rem 2rem',
        '&:hover': {
            backgroundColor: "#eeeeee",
            boxShadow: "0 10px 40px 0 rgba(189, 189, 189, 0.8)",
        },
        boxShadow: "0 10px 40px 0 rgba(189, 189, 189, 0.5)",
    },
    confirmButton: {
        margin: theme.spacing(1),
        backgroundColor: "#8bc34a",
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: '600',
        fontSize: '1.4rem',
        padding: '0.5rem 2rem',
        '&:hover': {
            backgroundColor: "#a2cf6e",
            boxShadow: "0 10px 40px 0 rgba(97, 136, 51, 0.8)",
        },
        boxShadow: "0 10px 40px 0 rgba(97, 136, 51, 0.5)",
    }
}));

const ProfileEditor = ({open, onClose, ...props}) => {
    const {user, setUser, userData, setUserData} = useContext(AuthContext)
    const classes = useStyles();
    const [displayName, setDisplayName] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [saving, setSaving] = useState(false)

    const clearData = () => {
        setDisplayName('')
        setSubtitle('')
        setAddress('')
        setMobile('')
        setPhone('')
        setEmail('')
    }

    useEffect(() => {
        clearData();
    }, [])

    useEffect(() => {
        if(user || userData) {
            setDisplayName(userData?.displayName ?? user?.displayName ?? '')
            setSubtitle(userData?.subtitle ?? user?.subtitle ?? '')
            setAddress(userData?.address ?? user?.address ?? '')
            setMobile(userData?.mobile ?? user?.mobile ?? '')
            setPhone(userData?.phone ?? user?.phone ?? '')
            setEmail(userData?.email ?? user?.email ?? '')
            
        }
    }, [open, user, userData])

    const handleSubmit = async () => {
        try {
            setSaving(true)
            if(!displayName) {
                return alert("Morate unesti naziv!");
            }

            const saveData = {displayName, subtitle, address, phone, mobile, email}
            await tenantService.updateTenant(saveData)
            setUserData(saveData)
            clearData();
            
            onClose()
        }
        catch(error) {
            console.error("Error while saving tenant info", error)
        }
        finally {
            setSaving(false)
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth="lg">
            <DialogTitle id="form-dialog-title">Uređivanje vaših podataka</DialogTitle>
            <DialogContent>
            <DialogContentText>
                {"Unesite podatke"}
            </DialogContentText>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Naziv"
                            value={displayName}
                            onChange={(event) => setDisplayName(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Opis"
                            value={subtitle}
                            onChange={(event) => setSubtitle(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Adresa"
                            value={address}
                            onChange={(event) => setAddress(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Tel."
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Mob."
                            value={mobile}
                            onChange={(event) => setMobile(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="E-mail"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>

            <Button onClick={handleSubmit} loading={saving} className={classes.confirmButton} variant="contained">
                Uredi
            </Button>
            <Button onClick={onClose} variant="contained" className={classes.defaultButton}>
                Odustani
            </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProfileEditor;