import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const NavButton = styled(Button)(({theme}) => ({
    color: "#7A3D79",
    borderRadius:"25px",
    boxShadow: 'none',
    width: '115px',
    backgroundColor: "#F3F7FB",
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '0.8rem!important', 
    paddingTop: '1.2rem',
    paddingBottom: '1.2rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.7rem!important'
    },
    '& .MuiButton-startIcon': {
        marginLeft: '0!important',
        marginRight: '0!important'
    },
    '&  .MuiButton-iconSizeLarge > *:first-child': {
        fontSize: '40px!important'
    },
    '&:hover': {
        backgroundColor: "#7A3D79",
        color: "#fff",
    },
}));

export default NavButton;