import React, { useContext } from 'react';
import {patientService, noteService, emailService } from '../../services';
import './OfferViewer.css'
import { useLocation} from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { AuthContext } from '../../context/AuthContext';

const OfferViewer = ({offer, patient, ...props}) => {
    const location = useLocation();
    const {user, userData} = useContext(AuthContext)

    React.useEffect(() => {
        console.log("Got offer:", offer, patient)
        
    }, [offer])

    const handleSendOffer = async () => {
        try {
            const res = await emailService.sendOfferEmail(patient, offer, user.email)
            if(res.status < 250) {
                console.log("Sent mail sucessfully:", res)
                alert("Ponuda uspješno poslana!")
                props.onClose();
            }
            else {
                alert("Dogodila se pogreška prilikom slanja e-maila!")
            }
        }
        catch(error) {
            console.log("Error while sending email:", error)
        }
    }

    if(!offer) {
        return null;
    }

    return (
      
        <Dialog 
            {...props}
            maxWidth="lg" 
            fullWidth="lg"
            className="offers"
            keepMounted
        >
            <DialogContent>
                <h2 className="offer-viewer-header">
                    {offer.name}
                    <Button onClick={handleSendOffer} className="send-offer" color="primary" variant="contained">Pošalji</Button>
                </h2>
                <div className="offers">
                    <a href={offer.webViewLink} target="_blank">Pregledaj datoteku</a> 
                </div>
            </DialogContent>
        </Dialog>
  );
}

export default OfferViewer;