import {createContext, useState, useRef, useEffect} from "react";
import axios from "axios";
import { tenantService } from "../services/tenant.service";
import AppConfig from "../config/AppConfig";

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('loggedInUser')))
    const [token, setAccessToken] = useState()
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('dent_ai_refresh_token'))
    const [invalidSession, setInvalidSession] = useState(false)
    const authInterceptorRef = useRef()
    const [userData, setUserData] = useState({})

    const setToken = (accessToken) => {
        authInterceptorRef.current = accessToken

        axios.interceptors.request.use(async function (request) {
            request.headers.common.Authorization = `Bearer ${authInterceptorRef.current}`
            return request;
        })

        axios.interceptors.response.use(function (response) {
            return response;
        }, async function (error) {
            const { config, message } = error;

            if (401 === error.response.status || 403 === error.response.status) {
                try {
                    const tokens = await axios.post(`${AppConfig.apiUrl}/auth/google/refresh-token`, {
                        refreshToken
                    });
                    
                    if(tokens.data.refresh_token) {
                        setRefreshToken(tokens.data.refresh_token)
                        localStorage.setItem('dent_ai_refresh_token', tokens.data.refresh_token)
                        authInterceptorRef.current = tokens.data.access_token
                        setAccessToken(tokens.data.access_token);
                        config.headers.common.Authorization = `Bearer ${tokens.data.access_token}`
                    }
    
                    return axios.request(config);
                }
                catch(error) {
                    setInvalidSession(true)
                    return Promise.reject(error);
                }
            } else {
                return Promise.reject(error);
            }
        });

        setAccessToken(accessToken)
    }


    return (
        <AuthContext.Provider value={{
            user,
            setUser,
            token,
            setToken,
            invalidSession, 
            setInvalidSession,
            userData, 
            setUserData,
            refreshToken,
            setRefreshToken
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContextProvider, AuthContext };