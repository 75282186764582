import logo from "../../assets/images/dent-ai-logo.png";

const Logo = (props) => {
  return (
    <div>
      <img src={logo} {...props} />
    </div>
  );
};

export default Logo;
