import './Sidebar.css'

export const Sidebar = ({position, ...props}) => {
    return (
        <aside className={`page-sidebar ${position || 'left'}`}>
            {props.children}
        </aside>
    )
}

export default Sidebar;