import React from 'react';


// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Fonts
import "./vendor/line-awesome/css/line-awesome.min.css";
import "./vendor/font-awesome/css/all.min.css";
import "./vendor/themify/themify-icons.css";

// StyleSheet
import './scss/main.scss';
import Index from './markup/pages';

function Home() {
	return (
		<div className="page-wraper">
			<Index />
		</div>
	);
}

export default Home;