import React, { useEffect, useState, useContext, useRef } from "react";

import Dashboard from "./pages/Dashboard";
import Patients from "./pages/Patients";
import Login from "./pages/Login";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/hr";
import "react-image-lightbox/style.css";
import PrivateRoute from "./components/PrivateRoute";
import { auth } from "./services/firebase.service";
import Home from "./pages/Home";
import {
  useLocation,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import { onAuthStateChanged } from "firebase/auth";
//import { auth, signOutUser } from './services/firebase.service';
import { AuthContext } from "./context/AuthContext";
import AppConfig from "./config/AppConfig";
import axios from "axios";
import { patientService } from "./services/patient.service";
import { useGoogleLogin } from "@react-oauth/google";
import Authorize from "./components/Authorize";
import { tenantService } from "./services/tenant.service";
import { usePatients } from "./hooks/usePatients";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import NeedApproval from "./components/NeedApproval";
//import PrivacyPolicy from "./pages/Privacy";
import PrivacyPolicy from "./pages/Home/markup/pages/policy";
import Terms from "./pages/Home/markup/pages/terms";
import AboutUs from "./pages/Home/markup/pages/about-us";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import googleDriveService from "./services/google-drive.service";
import Pricing from "./pages/Home/markup/pages/pricing";

function App() {
  const {
    user,
    setUser,
    token,
    setToken,
    invalidSession,
    setInvalidSession,
    userData,
    setUserData,
    setRefreshToken,
    refreshToken,
  } = useContext(AuthContext);
  const location = useLocation();
  const { mutate: mutatePatients, error: errorPatients } = usePatients();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState();
  console.log("Got location", location);
  const handleSuccess = async ({ code }) => {
    const tokens = await axios.post(`${AppConfig.apiUrl}/auth/google`, {
      code,
    });

    const userInfo = await axios
      .get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: { Authorization: `Bearer ${tokens.data.access_token}` },
      })
      .then((res) => res.data);

    let tenantData = {};
    try {
      tenantData = await tenantService.getTenantInfo(user?.uid);
      console.log("Got tenant data", { ...userInfo, ...tenantData });
      setUserData(tenantData);
    } catch (error) {
      console.error("error while getting tenant info", error);
    } finally {
      //setUser({...usr, ...tenantData})
    }

    setToken(tokens.data.access_token);
    setRefreshToken(tokens.data.refresh_token);
    localStorage.setItem("dent_ai_refresh_token", tokens.data.refresh_token);
    setUser({ ...userInfo, ...tenantData });
    setInvalidSession(false);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleSuccess,
    clientId: AppConfig.clientId,
    autoLoad: true,
    isSignedIn: true,
    discoveryDocs: AppConfig.discoveryDocs,
    onFailure: (response) => console.error("Error for google login:", response),
    scope: AppConfig.scope,
    prompt: "",
    flow: "auth-code",
  });

  onAuthStateChanged(auth, async (usr) => {
    setUser(usr);
    //setUserData(tenantData)
  });

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationMessage();
    setShowNotification(false);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const synced = await tenantService.getSyncedAttachments();
      const res = await patientService.syncFolders(synced);
      if (res) {
        if (res?.syncedAttachments?.length) {
          await tenantService.setSyncedAttachments(res.syncedAttachments);
          setNotificationMessage(
            `Zaprimili ste nove datoteke pacijenata: ${res.syncedAttachments.join(
              ","
            )}`
          );
        }

        res?.createdPatients?.map(
          async (p) => await patientService.importPatient(p)
        );
        if (res?.createdPatients?.length) {
          mutatePatients();
          setNotificationMessage(
            `Dodani novi pacijenti: ${res.createdPatients
              .map((p) => p.name)
              .join(",")}`
          );
        }

        if (res?.createdPatients?.length || res?.syncedAttachments.length) {
          setShowNotification(true);
        }
      }
    }, 1 * 60000); // (30 * 60000)
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (refreshToken) {
      axios.interceptors.response.use(
        function (response) {
          return response;
        },
        async function (error) {
          const { config, message } = error;

          if (401 === error.response.status || 403 === error.response.status) {
            try {
              const tokens = await axios.post(
                `${AppConfig.apiUrl}/auth/google/refresh-token`,
                {
                  refreshToken,
                }
              );

              setRefreshToken(tokens.data.refresh_token);
              localStorage.setItem(
                "dent_ai_refresh_token",
                tokens.data.refresh_token,
                config?.headers
              );

              setToken(tokens.data.access_token);
              console.log("Got config for failing request", config, tokens);
              config.headers.Authorization = `Bearer ${tokens.data.access_token}`;
              setInvalidSession(false);
              return axios.request(config);
            } catch (error) {
              setInvalidSession(true);
              return Promise.reject(error);
            }
          } else {
            return Promise.reject(error);
          }
        }
      );
    }
  }, [refreshToken, errorPatients, setInvalidSession, setRefreshToken, setToken]);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const tenantData = await tenantService.getTenantInfo(user?.uid);

          console.log("Got user", user, tenantData);
          setUserData(tenantData);

          try {
            let parent = await googleDriveService.list(
              "q=name = 'patients' and trashed = false and mimeType='application/vnd.google-apps.folder'&fields=files(id)"
            );
            if (!parent?.data?.files?.length) {
              const patientsFolder = await googleDriveService.create({
                name: "patients",
                mimeType: "application/vnd.google-apps.folder",
              });
              parent = patientsFolder;
            }
          } catch (err) {
            console.error("Got error when creating patients folder", err);
          }
        } catch (error) {
          console.error("error while getting tenant info", error);
        } finally {
          //setUser({...usr, ...tenantData})
        }
      }
    };
    fetchData();
  }, [user, setUserData]);

  const privateRoute = () => {
    return (
      location.pathname !== "/" &&
      location.pathname !== "/policy" &&
      location.pathname !== "/terms" &&
      location.pathname !== "/login" &&
      location.pathname !== "/home" &&
      location.pathname !== "/about-us" &&
      location.pathname !== "/pricing"
    ); // && location.pathname != "/contact-us"
  };

  const needsToAuthorize = !user || (user && (userData && (!token || !refreshToken || invalidSession)))

  return !user && privateRoute() ? (
    <Login />
  ) : (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment} locale={"hr"}>
        <Switch location={location}>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          {/* <Route path="/contact-us">
              <ContactUs />
            </Route> */}
          <Route path="/about-us">
            <AboutUs />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/pricing">
            <Pricing />
          </Route>
          <PrivateRoute path="/patients/dashboard">
            <Dashboard view="homepage" />
          </PrivateRoute>
          <PrivateRoute path="/patients/calendar">
            <Dashboard view="calendar" />
          </PrivateRoute>
          <PrivateRoute path="/patients">
            <Patients />
          </PrivateRoute>
        </Switch>

        {privateRoute() && needsToAuthorize ? (
          <Authorize googleLogin={googleLogin} />
        ) : null}
        {privateRoute() && user && !userData && (
          <NeedApproval googleLogin={googleLogin} />
        )}
      </LocalizationProvider>
      <Snackbar
        open={showNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={null}
        onClose={handleCloseNotification}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseNotification}
          severity="success"
        >
          {notificationMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default App;
