import React, { Component } from "react";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import MainBannerSection from "../elements/main-banner";
import AboutSection from "../elements/about";
import PricingSection from "./pricing";

class Index extends Component {
  render() {
    return (
      <>
        <Header />
        <MainBannerSection />
        <AboutSection />
        {/* <AppointmentSection /> */}
        <Footer />
      </>
    );
  }
}

export default Index;
