import './Header.css';

const Header = ({children, className, ...props}) => {
    return (
        <header className={`page-header ${className || ''}`} {...props}>
            {children}
        </header>
    )
}

export default Header;