import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import ptImg1 from "../../images/shap/wave-orange.png";
import ptImg2 from "../../images/shap/circle-small-blue.png";
import ptImg4 from "../../images/shap/square-dots-orange.png";
import ptImg5 from "../../images/shap/square-blue.png";
import pricing from "../../images/pricing.jpg";

class Pricing extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          {/* <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
               <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Cjenik</h1>
                </div>
              </div> 
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div> */}

          <section className="section-sp1 about-area">
            <div className="container">
              <div className="row align-items-center heading-bx">
                <div className="col-lg-12 mb-30">
                  <img src={pricing} alt="Cjenik" />
                </div>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
            <img className="pt-img2 animate2" src={ptImg2} alt="" />
            <img className="pt-img3 animate-rotate" src={ptImg5} alt="" />
            <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
            <img className="pt-img5 animate2" src={ptImg5} alt="" />
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Pricing;
