import axios from 'axios';

async function list(url) {
    return await axios.get(encodeURI(`https://www.googleapis.com/calendar/v3/users/me/calendarList?${url || ''}`))
}

async function getEvents(calendarId, params) {
    const url = Object.keys(params).map(key => key + '=' + params[key]).join('&')
    return await axios.get(encodeURI(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?${url || ''}`))
}



async function createEvent(calendarId, data) {
    const save = {...data, extendedProperties: {
        private: {
          email: data.email ?? ""
        }
    }}
    return await axios.post(encodeURI(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`), save)
}

async function deleteEvent(data) {
    return await axios.delete(encodeURI(`https://www.googleapis.com/calendar/v3/calendars/${data.calendarId}/events/${data.eventId}`))
}

async function updateEvent(data) {
    const save = {...data, extendedProperties: {
        private: {
          email: data.email
        }
    }}

    return await axios.put(encodeURI(`https://www.googleapis.com/calendar/v3/calendars/${data.calendarId}/events/${data.eventId}`), save)
}

export default {
    list,
    getEvents,
    createEvent,
    deleteEvent,
    updateEvent
}