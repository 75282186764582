import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ children, ...rest }) => {
    const {user, setUser} = useContext(AuthContext)


    return (
        <Route
            {...rest}
            render={({ location }) => {
                return user ? (
                    children
                    ) : 
                        <Redirect
                        to={{
                        pathname: "/login",
                        state: { from: location }
                        }}
                    />
            }

               
                    
                   
                    
                
                }
               
        />
    );
};

export default PrivateRoute;