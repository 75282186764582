import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'

import * as dates from 'date-arithmetic'
import { Calendar, Views, Navigate, DateLocalizer } from 'react-big-calendar'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import moment from 'moment'

export default function ThreeDayWorkWeek({
  date,
  localizer,
  max = moment().endOf('day'),
  min = moment().startOf('day'),
  scrollToTime = moment().startOf('day'),
 
  ...props
}) {
  
  const currRange = useMemo(
    () => ThreeDayWorkWeek.range(date, { localizer }),
    [date, localizer]
  )

  return (
    <TimeGrid
      date={moment(date).toDate()}
      eventOffset={15}
      localizer={localizer}
      max={moment(max).toDate()}
      min={moment(min).toDate()}
      range={currRange}
      scrollToTime={scrollToTime}
      {...props}
    />
  )
}

ThreeDayWorkWeek.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
}

ThreeDayWorkWeek.range = (date, { localizer }) => {
  const start = moment(date).clone()

  let current = start.clone()
  const range = []

  // implement moment less than equal to current 
  while(range.length < 3) {
   console.log("current", current.format("L"), current.day())
   if(![6, 0].includes(current.day())) {
    range.push(range.length == 2 ?current.clone().endOf('day'): current.clone().startOf('day'))
   }
    
    current.add(1, 'days')
  }

  return [...range]
}

const calculateWorkDay = (date, action, offset) => {
  const current = moment(date).clone()
  const range = []
  let i = 0;
    while(range.length < offset) {
      console.log("current", current.format("L"), current.day())
      if(![6, 0].includes(current.day())) {
        range.push(range.length == offset -1 ?current.clone().endOf('day'): current.clone().startOf('day'))
      }
       
      current.add(action == Navigate.PREVIOUS ? -1 : 1, 'days')
     }
  
     return range[range.length - 1];
}

ThreeDayWorkWeek.navigate = (date, action, { localizer }) => {
  console.log("Got handle navigate", date, action)
  return calculateWorkDay(date, action, 3)
}

ThreeDayWorkWeek.title = (date) => {
  console.log("Got title date", date)
  const start = moment(date).clone()
  const end =  calculateWorkDay(start.clone(), Navigate.NEXT, 3)
  return `${start.format('L')} - ${end.format('L')}`
}
