
import { db, auth } from './firebase.service'; 
import {collection, doc, getDoc, setDoc, query, getDocs, where } from "firebase/firestore";

export const tenantService = {
    updateTenant,
    getTenantInfo,
    setSyncedAttachments,
    getSyncedAttachments
};

async function getTenantInfo(userId) {
    console.log("Get tenant", userId)
    if(userId) {
        const userSnap = await getDoc(doc(db, 'tenants', userId));
        console.log("GOt user snap", userSnap, userId)
        if (userSnap.exists()) {
            return userSnap.data()
        }
    }

    return null
}

async function updateTenant(data) {
    if(auth?.currentUser?.uid && data) {
        const tenantsRef = doc(db, "tenants", auth?.currentUser?.uid);

        await setDoc(tenantsRef, {
            ...data,
            modifiedAt: (new Date().toISOString())
        },
        { merge: true });
    }
}

async function getSyncedAttachments() {
    if(auth?.currentUser?.uid) {
        const tenantsRef = doc(db, "tenants", auth?.currentUser?.uid);
        const tenantSnap = await getDoc(tenantsRef);
        if(tenantSnap.exists) {
            const tenantData = tenantSnap.data()
            return tenantData?.lastSyncedAttachments?.attachments ?? []
        }
    }

    return []
}

async function setSyncedAttachments(syncedAttachmentIds) {
    if(auth?.currentUser?.uid) {
        const tenantsRef = doc(db, "tenants", auth?.currentUser?.uid);

        let data = syncedAttachmentIds
        const tenantSnap = await getDoc(tenantsRef);
        if(tenantSnap.exists) {
            const tenantData = tenantSnap.data()
            if(tenantData?.lastSyncedAttachments?.attachments?.length) {
                data = [...new Set([...syncedAttachmentIds, ...tenantData.lastSyncedAttachments.attachments])]
            }
        }

        await setDoc(tenantsRef, {
            lastSyncedAttachments: {
                attachments: data,
                uploadedAt: (new Date().toISOString())
            }
        },
        { merge: true });
    }

    return null
}