import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

// Images
import logo from "../../images/dent-ai-logo.png";
import logoWhite from "../../images/dent-ai-logo-white.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Header = () => {
  const location = useLocation();

  useEffect(() => {
    // Mobile Menu Open
    var menuIcon = document.querySelector(".menuicon");
    var menuLinks = document.querySelector(".menu-links");
    var menuClose = document.getElementById("menuClose");

    menuIcon.addEventListener("click", function () {
      menuLinks.classList.add("show");
    });

    menuClose.addEventListener("click", function () {
      menuLinks.classList.remove("show");
    });

    // Mobile Submenu open close function
    var navMenu = [].slice.call(
      document.querySelectorAll(".menu-links > ul > li")
    );
    for (var y = 0; y < navMenu.length; y++) {
      navMenu[y].addEventListener("click", function () {
        menuClick(this);
      });
    }

    function menuClick(current) {
      const active = current.classList.contains("open");
      navMenu.forEach((el) => el.classList.remove("open"));

      if (active) {
        current.classList.remove("open");
        console.log("active");
      } else {
        current.classList.add("open");
        console.log("close");
      }
    }
  }, []);

  return (
    <>
      <header className="header header-transparent rs-nav">
        <Sticky enabled={true} className="sticky-header navbar-expand-lg">
          <div className="menu-bar clearfix">
            <div className="container-fluid clearfix">
              <div className="menu-logo logo-dark">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>

              <button
                className="navbar-toggler collapsed menuicon justify-content-end"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#menuDropdown"
                aria-controls="menuDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className="secondary-menu">
                {/* <Link to="/login" className="login-btn-responsive">
                    Prijava
                  </Link> */}
                <ul>
                  <li className="num-bx">
                    <a href="mailto:info@miraident.hr">
                      <i className="fas fa-envelope"></i>Zatražite demo
                    </a>
                  </li>
                  <li className="btn-area">
                    <Link to="/login" className="btn btn-primary shadow">
                      PRIJAVA{" "}
                      <i className="btn-icon-bx fas fa-chevron-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className="menu-links navbar-collapse collapse justify-content-end"
                id="menuDropdown"
              >
                <div className="menu-logo">
                  <Link to="/">
                    <img src={logoWhite} alt="" />
                  </Link>
                </div>
                <ul className="nav navbar-nav">
                  <li
                    className={`mobile-login-menu-btn ${
                      location.pathname == "/login" ? "active" : ""
                    }`}
                  >
                    <Link to="/login">Prijava</Link>
                  </li>
                  <li className={`${location.pathname == "/" ? "active" : ""}`}>
                    <Link to="/">Početna</Link>
                  </li>
                  <li
                    className={`${
                      location.pathname == "/about-us" ? "active" : ""
                    }`}
                  >
                    <Link to="/about-us">
                      <span>O nama</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      location.pathname == "/terms" ? "active" : ""
                    }`}
                  >
                    <Link to="/terms">
                      <span>Uvjeti i pravila korištenja</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      location.pathname == "/policy" ? "active" : ""
                    }`}
                  >
                    <Link to="/policy">
                      <span>Politika privatnosti</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      location.pathname == "/pricing" ? "active" : ""
                    }`}
                  >
                    <Link to="/pricing">
                      <span>Cjenik</span>
                    </Link>
                  </li>

                  {/* <li><Link to="/contact-us">Contact Us</Link></li> */}
                </ul>
                <ul className="social-media">
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/profile.php?id=61558793041711"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.google.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-google"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                </ul>
                <div className="menu-close" id="menuClose">
                  <i className="ti-close"></i>
                </div>
              </div>
            </div>
          </div>
        </Sticky>
      </header>
    </>
  );
};

export default Header;
