import useSWR from "swr";
import { patientService } from "../services/patient.service";

const fetcher = () => patientService.getAllPatients()

export const usePatients = () => {
    const { data, error, mutate, isValidating } = useSWR(
        "/api/patients",
        fetcher
      );

    return { 
        data,
        error, 
        mutate, 
        isValidating 
    }
}
